import React, {useEffect, useRef, useState} from "react";
import {selectors} from "@/presentation/shared/ui/message";
import {mkdsEndpoint} from "@/data/api_entities/mkds/mkds_endpoint";
import {FormattedMessage} from "react-intl";
import OwnerMkdCard from "@/presentation/components/pages/OwnerMainPage/components/MKDList/OwnerMkdCard";
import Loader from "@/presentation/components/loader/Loader";
import ownerApartmentEndpoints from "@/data/api_entities/owner_apartment/owner_apartment.endpoints";

const OwnerMKDSList = () => {
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)
  const setMessage = selectors.useSetMessage()
  const getMkdsRequestController = useRef(null)


  const getMkds = async () => {
    try {
      setLoading(true)
      getMkdsRequestController.current?.abort();
      const {promise, controller} = ownerApartmentEndpoints.getMansions()
      getMkdsRequestController.current = controller;

      const res = await promise;
      if (Array.isArray(res)) {
        setList(res)
      } else {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось загрузить список домов',
          type: 'error',
          count: 3000
        })
      }
      setLoading(false)
    } catch (err) {
      if (err.name === 'AbortError') return
      setLoading(false)
      setMessage({
        header: <FormattedMessage id={'requests.error'}/>,
        message: 'Не удалось загрузить список домов',
        type: 'error',
        count: 3000
      })
    }
  }

  useEffect(() => {
    getMkds()
  }, [])

  return (
    <Loader loading={loading} styles={{
      wrapper: (base) => ({
        ...base,
        display: 'flex',
        flex: '3 1',
        height: '100%',
        // width: '100%',
        maxWidth: '100%',
        overflow: 'auto',
        // flex: 3,
        paddingRight: loading
          ? '0.625rem'
          : ''
      })
    }}>
      <div className='mkd-list'>
        {list.map(data => <OwnerMkdCard data={data}/>)}
      </div>
    </Loader>
  );
}

export default OwnerMKDSList;
