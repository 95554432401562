import {useNavigate} from "react-router-dom";
import icons from "@/assets/svg/icons/icons.svg";
import {MKDHelper} from "@/presentation/components/heatCalculationPage/helpers";
import React from "react";
import AlertTooltip from "@/presentation/components/pages/OwnerMainPage/components/AlertTooltip/AlertTooltip";

const OwnerMkdCard = ({data}) => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(`${data.id}`)
  }

  return (
    <div className={'mkd-card'} onClick={handleClick}>
      <div className="mkd-card__body">
        <div className="mkd-card__img">
          <svg>
            <use href={`${icons}#mansions`}/>
          </svg>
        </div>

        <div className="mkd-card__address">
          <span>{MKDHelper.getAddress(data)}</span>
          <span>{MKDHelper.getRegion(data)}</span>
        </div>
      </div>

      {
        data?.enumerator_editable && <div
          style={{
            position: 'absolute',
            top: '1rem',
            right: '1rem'
          }}
        >
          <AlertTooltip />
        </div>
      }

    </div>
  )
}

export default OwnerMkdCard;
