export const PATHS = {
  settings: 'settings',
  apartments: 'apartments',
  calculations: 'calculations',
  newApartment: 'add',
  newHeatMeter: 'add_enumerator',
  owner: 'owner'
}

export const MKD_PAGE_TABS = [
  {
    id: 1,
    textId: 'Параметры МКД',
    path: PATHS.settings
  },
  {
    id: 2,
    textId: 'Квартиры',
    path: PATHS.apartments
  },
  {
    id: 3,
    textId: 'Расчеты',
    path: PATHS.calculations
  }
]
