import React, {useEffect} from 'react';
import './App.sass';
import {Route, Routes, useLocation, useNavigate} from "react-router-dom"

import Main from '../main/Main.jsx'
import {Message} from '@/presentation/shared/ui/message'
import SignIn from "../authorization/SignIn";
import Registration from "../authorization/Registration";
import RecoveryPassword from "../authorization/RecoveryPassword";
import ConfirmationLetter from "../authorization/ConfirmationLetter";
import RecoveryPasswordForm from "../authorization/RecoveryPasswordForm";
import * as Sentry from "@sentry/react";
import {IntlProvider} from "react-intl";
import {messages} from "@/i18n/messages";
import {appSelectors} from "@/internal/lib/storeModels/models/app/appModels";
import MainPage from "@/presentation/components/mainPage/MainPage";
import MainPageService from "@/presentation/components/mainPageService/MainPageService";
import OwnerMainPage from "@/presentation/components/pages/OwnerMainPage/OwnerMainPage";

const App = () => {
  const locale = appSelectors.locale.useValue()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/user', {
        replace: true,
      })
    }
  }, [])

  // @ts-ignore
  return (
    <IntlProvider
      messages={messages[locale]}
      locale={locale}
      defaultLocale={'ru'}
    >
      <div className="content-wrapper" id={'main-wrapper'}>
        <Routes>
          <Route path="/input" element={<SignIn />}/>
          <Route path="/registration" element={<Registration />}/>
          <Route path="/password_recovery" element={<RecoveryPassword />}/>
          <Route path="/email_approve/*" element={<ConfirmationLetter />}/>
          <Route path="/forgot_password/*" element={<RecoveryPasswordForm />}/>
          {/*<Route path={"/*"} element={<MainPage />} />*/}
          <Route path="/user/*" element={<MainPage />} />
          <Route path="/service/*" element={<MainPageService />} />
          <Route path="/main/*" element={<Main/>}/>
          <Route path={'/owner/*'} element={<OwnerMainPage />}/>
        </Routes>
        <Message/>
      </div>
    </IntlProvider>
  )
}

export default Sentry.withProfiler(App)
