import { NavLink} from "react-router-dom";
import {ImgLinkArrow} from "@/assets/svg";
import {FormattedMessage} from "react-intl";

const LinkTab = ({subText, path, textId = '', text = null, onClick, extraComponent}) => {
  return (
    <NavLink
      className={`link-tab`}
      to={path}
      onClick={onClick ?? (() => {})}
    >
      <div className='link-tab__text'>
        <span>
          {text ?? <FormattedMessage id={textId}/>}
        </span>

        <span className='link-tab__sub-text'>{subText}</span>
      </div>

      <div className="link-tab__arrow">
        <img src={ImgLinkArrow} alt="arrow"/>
      </div>

      {extraComponent ? extraComponent : null}
    </NavLink>
  )
}

export default LinkTab
