import ArrowBack from "@/presentation/components/common/ArrowBack";
import {TARIFFS_PATH} from "../../const";

import {getTariffs} from "./queries/useBalances";
import {useEffect, useState} from "react";
import {balanceEndpoint} from "@/data/api_entities/balance/balance_endpoint";
import {FormattedDisplayName, FormattedMessage, FormattedNumber} from "react-intl";

const TariffsList = () => {
  const [tariffs, setTariffs] = useState([]);

  useEffect(() => {
    getTariffs().then((result) => {
      const tariffsItems = result.map(tariff => {
        return (
          <div key={tariff.id} className='tariffs__item'>
            <span>{tariff.device}</span>
            <span>
              <FormattedNumber value={tariff.coast} style="currency" currency={'RUB'}/>
            </span>
          </div>
        )
      })
      setTariffs(tariffsItems)
    })
  }, [])

  return (
    <div className='user-page__column user-page__column_tariff'>
      <ArrowBack
        pathOnVisible={TARIFFS_PATH}
      />

      <h2 className="title">
        <FormattedMessage id={'userPage.tariffs'}/>
      </h2>

      <p>
        <FormattedMessage id={'userPage.tariffsDescription'}/>
      </p>

      <div className="tariffs">
        <div className="tariffs__header">
          <h4 className="title_h4">
            <FormattedMessage id={'userPage.device'}/>
          </h4>

          <span>
            <FormattedMessage id={'userPage.onMonth'}/>
          </span>
        </div>

        <div className="tariffs__list">
          {tariffs}
        </div>
      </div>

      <h4 className="user-page__tariff-h4 title_h4">
        <FormattedMessage id={'userPage.additionalServices'}/>
      </h4>

      <div className="user-page__add-services">
        <span>
          <FormattedMessage id={'userPage.smsService'}/>
        </span>

        <span>
          <FormattedNumber value={3} style="currency" currency={'RUB'}/>
        </span>
      </div>

      <div className="user-page__add-services">
        <span>
          <FormattedMessage id={'userPage.manualReading'}/>
        </span>

        <span>
          <FormattedMessage id={'userPage.partOfTariff'}/>
        </span>
      </div>

      <div className="user-page__add-services">
        <span>
          <FormattedMessage id={'userPage.efficiencyAnalysis'}/>
        </span>

        <span>
          <FormattedMessage
            id={'userPage.priceForOneObject'}
            values={{
              price: 60
            }}
          />
        </span>
      </div>

      <div className="user-page__add-services">
        <span>
          Расчет теплопотребления МКД с учетом показаний распределителей
        </span>

        <span>
          <FormattedNumber value={3} style="currency" currency={'RUB'}/> за распределитель, но не менее <FormattedNumber value={9} style="currency" currency={'RUB'}/> за квартиру
        </span>
      </div>
    </div>
  )
}

export default TariffsList
