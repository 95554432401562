import Select, {components} from "react-select";
import {Dropdown} from "@/assets/svg";
import {customStyles, customStylesError} from "@/presentation/components/common/const";
import {useEffect, useRef, useState} from "react";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import DefaultModal from "@/presentation/components/common/modals/DefaultModal";
import RegisterOwnerForm
  from "@/presentation/components/heatCalculationPage/components/MKDPage/components/ApartmentOwnerPage/components/RegisterOwnerForm";
import {
  newOwnerFormControllerSelectors
} from "@/presentation/components/heatCalculationPage/components/MKDPage/components/ApartmentOwnerPage/form/controller";

const FindOwnerSelect = ({onSearch, onRegister, ...props}) => {
  const TIMEOUT_MS = 300
  const timeoutId = useRef(null);
  const [openRegisterModal, toggleRegisterModal] = useToggle()
  const selectRef = useRef()

  const isValidForm = newOwnerFormControllerSelectors.useIsFormValid()
  const resetForm = newOwnerFormControllerSelectors.useResetForm()
  const setSubmitHandler = newOwnerFormControllerSelectors.useSetOnValidSubmit()
  const submitForm = newOwnerFormControllerSelectors.useOnClickSubmit();

  const [registrationLoading, setRegistrationLoading] = useState(false)

  const handleRegistration = async (data) => {
    if (!onRegister) return;
    try {
      setRegistrationLoading(true)
      await onRegister(data);
    } catch (e) {
      console.log(e)
    } finally {
      setRegistrationLoading(false)
    }
  }

  useEffect(() => {
    setSubmitHandler(handleRegistration)
    return () => {
      resetForm();
    }
  }, [])

  const handleSubmitForm = async () => {
    if (!isValidForm) return;

    submitForm();
  }

  const clearSearchTimeout = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }
  }

  useEffect(() => {
    return () => clearSearchTimeout()
  }, [])

  const handleInputChange = (newValue) => {
    if (newValue.trim().length && timeoutId.current) {
      clearSearchTimeout();
      timeoutId.current = setTimeout( () => {
        if (onSearch) {
          onSearch(newValue)
        }

        timeoutId.current = null;
      }, TIMEOUT_MS)
    } else {
      clearSearchTimeout();
      timeoutId.current = -1;
      onSearch(newValue);
    }
  }

  const handleOpenRegisterForm = () => {
    selectRef.current?.blur()
    toggleRegisterModal();
  }

  const Menu = (props) => {
    return (
      <components.Menu {...props}>
        <div
          className='select__add-option'
          style={{
            borderBottom: '1px solid #ECF0F6',
          }}
        >
          <div
            onClick={handleOpenRegisterForm}
          >
            + Зарегистрировать
          </div>
        </div>

        {props.children}
      </components.Menu>
    )
  }

  const handleToggleRegisterForm = () => {
    if (registrationLoading) return;

    toggleRegisterModal();
    resetForm();
  }

  return (
    <>
      <Select
        ref={selectRef}
        className={`select-component`}
        placeholder={null}
        components={{ DropdownIndicator, Menu }}
        styles={{
          ...customStyles,
          option: (provided) => ({
            padding: '.75rem',
            borderBottom: '1px solid #ECF0F6',
            color: '#36455D',
            fontSize: '.875rem',
            cursor: 'pointer',
            ':hover': {
              background: '#E6EFFE'
            },
            ':last-child': {
              borderBottom: 'none'
            },
            '.find-owner-select-value': {
              display: 'flex',
              flexDirection: 'column',
              'span:last-child': {
                fontSize: '0.85rem',
                fontWeight: 500
              }
            }
          }),
          singleValue: (provided) => ({
            ...provided,
            fontSize: '0.875rem',
            lineHeight: '142.86%',
            color: '#36455D',
            margin: 0,
            padding: '0.625rem 0.5625rem',
            overflow: 'hidden',
            '.find-owner-select-value': {
              display: 'flex',
              flexDirection: 'column',
              'span:last-child': {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                fontSize: '0.85rem',
              }
            }
          })
        }}
        autoFocus={false}
        maxMenuHeight={180}
        menuPosition={'absolute'}
        menuPlacement={'auto'}
        onInputChange={handleInputChange}
        isSearchable
        noOptionsMessage={(params) => {
          return params?.inputValue?.trim().length ? 'Ничего не найдено' : 'Введите значение'
        }}
        loadingMessage={() => 'Загрузка...'}
        filterOption={() => true}
        {...props}
      />

      <DefaultModal
        isOpen={openRegisterModal}
        toggleModal={handleToggleRegisterForm}
        headerTitle={'Регистрация собственника'}
        bodyText={<RegisterOwnerForm />}
        agreeBtnConfig={{
          className: 'btn',
          text: 'Зарегистрировать',
          disabled: registrationLoading || !isValidForm
        }}
        loading={registrationLoading}
        onAgree={handleSubmitForm}
        onDisagree={handleToggleRegisterForm}
        closeOnOutside={false}
      />
    </>
  )
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={Dropdown} alt="Dropdown"/>
    </components.DropdownIndicator>
  )
}

export default FindOwnerSelect
