import {NavLink, Route, Routes, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {apartmentsEndpoint} from "@/data/api_entities/apartments/apartments_endpoint";
import {FormattedMessage} from "react-intl";
import {selectors} from "@/presentation/shared/ui/message";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import {PATHS} from "@/presentation/components/heatCalculationPage/components/MKDPage/const";
import {MKDHelper} from "@/presentation/components/heatCalculationPage/helpers";
import HoveredPopover from "@/presentation/components/common/HoveredPopover";
import LinkTab from "@/presentation/components/common/LinkTab";
import Loader from "@/presentation/components/loader/Loader";
import OwnerEnumerator from "@/presentation/components/pages/OwnerMainPage/components/OwnerEnumerator/OwnerEnumerator";
import ReportsHistory from "@/presentation/components/pages/OwnerMainPage/components/ReportsHistory/ReportsHistory";
import AlertTooltip from "@/presentation/components/pages/OwnerMainPage/components/AlertTooltip/AlertTooltip";

const OwnerApartmentPage = ({apartments, updateAlertTrigger, toggleAlertTrigger, mkdData}) => {
  const {id} = useParams()
  const [loadingEnumerators, setLoadingEnumerators] = useState(true)
  const [enumerators, setEnumerators] = useState([])
  const getEnumeratorsRequestController = useRef()
  const setMessage = selectors.useSetMessage()
  const selectedApartment = apartments.find(item => item.id == id)

  useEffect(() => {
    if (!id) return
    setLoadingEnumerators(true)
    getEnumeratorsRequestController.current?.abort();

    const {promise, controller} = apartmentsEndpoint.getEnumerators(id)
    getEnumeratorsRequestController.current = controller
    promise
      .then(res => {
        if (Array.isArray(res)) {
          setEnumerators(res)
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось загрузить список отопительных приборов',
            type: 'error',
            count: 3000
          })
        }
        setLoadingEnumerators(false)
      })
      .catch(err => {
        if (err.name === 'AbortError') return
        setLoadingEnumerators(false)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось загрузить список отопительных приборов',
          type: 'error',
          count: 3000
        })
      })
  }, [id, updateAlertTrigger])


  return (
    <>
      <div className={'apartment-page'}>
        <ArrowBack
          pathOnVisible={PATHS.apartments}
          withId={true}
        />

        <div className="apartment-page__title-wrapper">
          <h2 className="title">
            {MKDHelper.getAddressWithApartments(mkdData, selectedApartment?.kv)}
          </h2>
        </div>

        <div style={{marginBottom: '24px'}}>
          <LinkTab
            path={'reports_history'}
            text={'Расчеты'}
          />

        </div>

        <div className={'apartment-page__list-title'}>
          <h4>Отопительные приборы</h4>
        </div>

        <Loader styles={{
          wrapper: (base) => ({
            ...base,
            width: '100%',
            overflow: 'auto',
            minHeight: '100px'
          }),
        }} loading={loadingEnumerators} >
          {!!enumerators.length
            ? enumerators.map(enumerator => <LinkTab
              key={enumerator.id}
              path={`${enumerator.id.toString()}`}
              text={`ОП №${enumerator.number}`}
              extraComponent={enumerator?.enumerator_editable ? <div style={{
                position: "absolute",
                top: '50%',
                transform: 'translateY(-50%)',
                right: '3rem'
              }}>
                <AlertTooltip text={'Нет показаний'}/>
              </div> : null}
              // onClick={() => setEditEnumeratorId(enumerator.id)}
            />)
            : <p className={'apartments-page__empty-list'}>Список пуст</p>
          }
        </Loader>
      </div>

      <Routes>
        <Route
          path={'/:id'}
          element={<OwnerEnumerator toggleAlertTrigger={toggleAlertTrigger}/>}
        />

        <Route
          path={'/reports_history'}
          element={<ReportsHistory />}
        />
      </Routes>
    </>
  )
}

export default OwnerApartmentPage;
