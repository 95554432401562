import Select, {components} from "react-select";
import {SEARCH_SELECT_STYLES} from "../const";
import {Search} from "@/assets/svg";
import {FormattedMessage} from "react-intl";
import React, {useEffect, useRef, useState} from "react";

const SearchSelect = (
  {
    placeholder = '',
    noOptionsMessage = () => <FormattedMessage id={'app.nothingFound'}/>,
    onSelect,
    searchOptionsByValue,
    isLoading
  }) => {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const searchTimeout = useRef(null)

  useEffect(() => {
    handleSearch('')
    return () => {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current)
      }
    }
  }, [])

  const handleSearch = (value) => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current)
    }

    const timeout = setTimeout(async () => {
      const options = await searchOptionsByValue(value)
      if (options && Array.isArray(options)) {
        setOptions(options);
      }
    }, 300)

    searchTimeout.current = timeout
  }

  const handleChangeInputValue = (value) => {
    setInputValue(value)
    handleSearch(value)
  }
  
  const handleChange = (option) => {
    onSelect(option)
  }

  return (
    <Select
      isSearchable
      menuPosition={'absolute'}
      menuPlacement={'auto'}
      maxMenuHeight={480}
      options={options}
      autoFocus={false}
      onChange={handleChange}
      isLoading={isLoading}
      escapeClearsValue={true}
      filterOption={() => true}
      loadingMessage={() => 'Загрузка...'}
      inputValue={inputValue}
      onInputChange={handleChangeInputValue}
      components={{DropdownIndicator, SingleValue}}
      styles={SEARCH_SELECT_STYLES}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage}
    />
  )
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={Search} alt="Search"/>
    </components.DropdownIndicator>
  )
}

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      {props.data?.item.name ?? '-'}
    </components.SingleValue>
  )
}

export default SearchSelect
