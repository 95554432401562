import ArrowBack from "@/presentation/components/common/ArrowBack";
import React from "react";
import HistoryList
  from "./components/HistoryList";
import {useParams} from "react-router-dom";

const ReportsHistory = () => {
  const {id} = useParams();
  return (
    <div className={'reports-history'}>
      <ArrowBack
        pathOnVisible={'reports_history'}
      />

      <h2 className="title">
        История расчетов
      </h2>

      <HistoryList apartmentId={id}/>
    </div>
  )
}

export default ReportsHistory;
