import StoreModelFabric from "@/internal/lib/storeModels/fabrics/storeModelFabric";

const mkdsState = new StoreModelFabric([])
const loadingState = new StoreModelFabric(true)
const sideFormState = new StoreModelFabric(null)
const isNewMKDState = new StoreModelFabric(false)
const selectedMKDSettingsState = new StoreModelFabric()
const selectedMKDState = new StoreModelFabric()
const apartmentsListState = new StoreModelFabric([])
const enumeratorsListState = new StoreModelFabric([])
const findApartmentsListState = new StoreModelFabric(null)
const updateAlertTriggerController = new StoreModelFabric(false)

export const mkdsStateSelectors = {
  list: mkdsState.createSelectors(),
  loading: loadingState.createSelectors(),
  sideForm: sideFormState.createSelectors(),
  isNewMKD: isNewMKDState.createSelectors(),
  selectedMKDSettingsId: selectedMKDSettingsState.createSelectors(),
  selectedMKD: selectedMKDState.createSelectors(),
  apartmentsList: apartmentsListState.createSelectors(),
  enumeratorsList: enumeratorsListState.createSelectors(),
  findApartmentsList: findApartmentsListState.createSelectors(),
  updateAlertTrigger: updateAlertTriggerController.createSelectors()
}
