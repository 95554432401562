export const FIRST_EMAIL_INPUT = Object.freeze({
  value: '',
  validationMsg: []
})

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? 0 : 0,
    border: '1px solid #ECF0F6',
    borderRadius: '0.375rem',
    ':hover': {
      border: '1px solid #ECF0F6'
    },
    minHeight: 'unset',
    cursor: 'pointer',
    backgroundColor: state.isDisabled ? 'rgba(239, 239, 239, 0.3)' : 'transparent',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: state.isMulti ? '' : 0,
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: '0.625rem 0.5625rem',
    fontSize: '0.875rem',
    lineHeight: '0 !important',
    color: '#36455D',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '0.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    margin: 0,
    padding: '0.625rem 0.5625rem',
    display: 'flex',
    alignItems: 'center',
    'div': {
      maxWidth: '1rem',
      height: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '.25rem',
      'img': {
        display: 'block',
        objectFit: 'contain',
        maxHeight: '100%',
      }
    },
    'span': {
      color: 'inherit',
      marginTop: '.125rem',
      fontSize: '.875rem',
      lineHeight: '142.86%',
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '0.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    margin: 0,
    padding: '0.625rem 0.5625rem'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  dropdownIndicator: (provided, state) => ({
    padding: '0 .75rem',
    transition: 'transform .1s ease',
    transform: `rotate(${state.selectProps.menuIsOpen ? '180deg' : '0'})`
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
    maxWidth: '100%',
    background: '#fff',
    boxShadow: '0 .5rem 1rem rgba(53, 96, 181, 0.2)',
    borderRadius: '.375rem',
    overflowX: 'hidden',
    margin: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '0'
  }),
  loadingMessage: (provided) => ({
    ...provided,
    fontSize: '.875rem',
    lineHeight: '142.86%',
    color: '#BBC4D2',
  }),
  option: () => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: '.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    padding: '.75rem',
    borderBottom: '1px solid #ECF0F6',
    cursor: 'pointer',
    ':hover': {
      background: '#E6EFFE'
    },
    ':last-child': {
      borderBottom: 'none'
    },
    'div': {
      width: '1rem',
      height: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '.25rem',
      'img': {
        display: 'block',
        objectFit: 'contain',
        maxHeight: '100%',
      }
    },
    'span': {
      color: 'inherit',
      marginTop: '.125rem',
      fontSize: '.875rem',
      lineHeight: '142.86%',
    }
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontSize: '.875rem',
    lineHeight: '142.86%',
    color: '#BBC4D2',
  })
}

export const customStylesError = {
  ...customStyles,
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? 0 : 0,
    border: '1px solid #FC4F6E',
    borderRadius: '0.375rem',
    ':hover': {
      border: '1px solid #FC4F6E'
    },
    minHeight: 'unset',
    cursor: 'pointer',
    backgroundColor: state.isDisabled ? 'transparent' : 'transparent',
  }),
}

export const SELECT_BTN_STYLES = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? 0 : 0,
    border: 'none',
    borderRadius: '2.358em',
    ':hover': {
      border: 'none'
    },
    minHeight: 'unset',
    cursor: 'pointer',
    backgroundColor: state.isDisabled ? 'rgba(239, 239, 239, 0.3)' : 'transparent',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    width: 'fit-content'
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    fontSize: '0.875rem',
    lineHeight: '0 !important',
    color: '#fff',
  }),
  singleValue: (provided) => ({
    ...provided,
    padding: '.714em 0 .714em 1em',
    fontSize: '0.875rem',
    lineHeight: '142.86%',
    color: '#fff',
    margin: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '0.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    margin: 0,
    padding: '0.625rem 0.5625rem'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  dropdownIndicator: (provided, state) => ({
    padding: '0 .5rem',
    transition: 'transform .1s ease',
    transform: `rotate(${state.selectProps.menuIsOpen ? '180deg' : '0'})`
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
    background: '#fff',
    boxShadow: '0 .5rem 1rem rgba(53, 96, 181, 0.2)',
    borderRadius: '.375rem',
    overflowX: 'hidden',
    margin: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '0',
  }),
  option: () => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: '.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    padding: '.75rem',
    borderBottom: '1px solid #ECF0F6',
    cursor: 'pointer',
    ':hover': {
      background: '#E6EFFE'
    },
    ':last-child': {
      borderBottom: 'none'
    },
    'div': {
      width: '1rem',
      height: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '.25rem',
      'img': {
        display: 'block',
        objectFit: 'contain',
        maxHeight: '100%',
      }
    },
    'span': {
      color: 'inherit',
      marginTop: '.125rem',
      fontSize: '.875rem',
      lineHeight: '142.86%',
    }
  }),
}

export const SEARCH_SELECT_STYLES = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? 0 : 0,
    border: '1px solid #ECF0F6',
    borderRadius: '0.375rem',
    ':hover': {
      border: '1px solid #ECF0F6'
    },
    minHeight: 'unset',
    cursor: 'pointer',
    backgroundColor: state.isDisabled ? 'rgba(239, 239, 239, 0.3)' : 'transparent',
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: '0.625rem 0.5625rem',
    fontSize: '0.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '0.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    margin: 0,
    padding: '0.625rem 0.5625rem',
    display: 'flex',
    alignItems: 'center',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '0.875rem',
    lineHeight: '142.86%',
    color: '#BBC4D2',
    margin: 0,
    padding: '0.625rem 0.5625rem'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
    maxWidth: '100%',
    background: '#fff',
    boxShadow: '0 .5rem 1rem rgba(53, 96, 181, 0.2)',
    borderRadius: '.375rem',
    overflowX: 'hidden',
    margin: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '0'
  }),
  option: () => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: '.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    padding: '.75rem',
    borderBottom: '1px solid #ECF0F6',
    cursor: 'pointer',
    ':hover': {
      background: '#E6EFFE'
    },
    ':last-child': {
      borderBottom: 'none'
    },
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontSize: '.875rem',
    lineHeight: '142.86%',
    color: '#BBC4D2',
  })
}

export const ASYNC_SELECT_STYLES = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? 0 : 0,
    border: '1px solid #ECF0F6',
    borderRadius: '0.375rem',
    ':hover': {
      border: '1px solid #ECF0F6'
    },
    minHeight: 'unset',
    cursor: 'pointer',
    backgroundColor: state.isDisabled ? 'rgba(239, 239, 239, 0.3)' : 'transparent',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: state.isMulti ? '' : 0,
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: '0.625rem 0.5625rem',
    fontSize: '0.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    gridTemplateColumns: '0 auto',
    'input': {
      opacity: '1 !important',
      borderRadius: '0',
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '0.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    margin: 0,
    padding: '0.625rem 0.5625rem',
    display: 'flex',
    alignItems: 'center',
    'div': {
      maxWidth: '1rem',
      height: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '.25rem',
      'img': {
        display: 'block',
        objectFit: 'contain',
        maxHeight: '100%',
      }
    },
    'span': {
      color: 'inherit',
      marginTop: '.125rem',
      fontSize: '.875rem',
      lineHeight: '142.86%',
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '0.875rem',
    lineHeight: '142.86%',
    color: '#727A86',
    margin: 0,
    padding: '0.625rem 0.5625rem'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
    maxWidth: '100%',
    background: '#fff',
    boxShadow: '0 .5rem 1rem rgba(53, 96, 181, 0.2)',
    borderRadius: '.375rem',
    overflowX: 'hidden',
    margin: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '0'
  }),
  option: () => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: '.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    padding: '.75rem',
    borderBottom: '1px solid #ECF0F6',
    cursor: 'pointer',
    ':hover': {
      background: '#E6EFFE'
    },
    ':last-child': {
      borderBottom: 'none'
    },
    'div': {
      width: '1rem',
      height: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '.25rem',
      'img': {
        display: 'block',
        objectFit: 'contain',
        maxHeight: '100%',
      }
    },
    'span': {
      color: 'inherit',
      marginTop: '.125rem',
      fontSize: '.875rem',
      lineHeight: '142.86%',
    }
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontSize: '.875rem',
    lineHeight: '142.86%',
    color: '#BBC4D2',
  }),
  loadingMessage: (provided) => ({
    ...provided,
    fontSize: '.875rem',
    lineHeight: '142.86%',
    color: '#BBC4D2',
  }),
}

export const ASYNC_SELECT_STYLES_ERROR = {
  ...ASYNC_SELECT_STYLES,
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? 0 : 0,
    border: '1px solid #FC4F6E',
    borderRadius: '0.375rem',
    ':hover': {
      border: '1px solid #FC4F6E'
    },
    minHeight: 'unset',
    cursor: 'pointer',
    backgroundColor: state.isDisabled ? 'rgba(239, 239, 239, 0.3)' : 'transparent',
  }),
}
