import HoveredPopover from "@/presentation/components/common/HoveredPopover";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

const AlertTooltip = ({text = 'Приборы без показаний'}) => {
  return (
    <HoveredPopover text={text} >
      <ReportGmailerrorredIcon style={{
        fill: 'red'
      }}/>
    </HoveredPopover>
  )
}

export default AlertTooltip;
