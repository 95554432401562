import TabBar from "@/presentation/components/mainPage/components/TabBar";
import React from "react";

const Tabs = ({tabs, setMode}) => {
  return (
    <div className={'main-tabs'}>
      {
        tabs.map((text, index)=>{
          return(
            <TabBar text={text[0]} onlyOne={tabs.length === 1} onClick={() => setMode(index)} key={index} link={text[1]}/>
          )
        })
      }
    </div>
  )
}

export default Tabs
