import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'userPage'
const translations = {
  'logOut': 'Log out',
  'deleteAcc': 'Delete account',
  'balanceAndPoints': 'Balance – {bonus, number} points and {balance, number, ::currency/EUR}',
  'accountDeleted': 'Your account has been deleted',
  'accAndRates': 'Account and rates',
  'myData': 'My details',
  'accountingObjects': 'Accounting objects',
  'accesses': 'Access',
  'deleteAcc?': 'Delete account?',
  'requisites': 'Requisites',
  'personalAccount': 'Personal account',
  'howCanICallU': 'How to contact you',
  'tellNotReq': 'Phone (optional)',
  historyTransaction: 'Transaction history',
  tariffs: 'Tariffs',
  balance: 'Balance',
  topUp: 'Top up',
  subscription: 'Your subscription',
  onMonth: 'for 30 days',
  monthTotal: 'Total for 30 days:',
  topUping: 'Replenishment',
  writeOff: 'Write-off',
  writeOffHistory: 'Write-off history',
  keepHistory: 'We keep a history of all transactions for the last 3 years.',
  latestTransactions: 'Latest transactions',
  noTransactions: 'No completed transactions',
  topUpAccount: 'Top up account',
  bankCard: '1. Bank card',
  byPlatron: 'Through Platron',
  paymentAmount: 'Payment amount',
  changeEmail: 'Change email',
  enterCode: 'Enter code',
  ifNoMail: 'If there is no mail, please check the correctness of the entered address and try again',
  ifChangeMail: 'If you change the e-mail, a confirmation email will be sent to it. If you ignore it, the e-mail will not be changed',
  sendCode: 'Send code',
  legalEntityName: 'Legal entity name',
  legalAddress: 'Legal address',
  actualAddress: 'Actual address',
  ifActualIsLegal: 'If the actual address is the same as the legal address',
  contactCommunication: 'Communication contact',
  inn: 'INN',
  changePassword: 'Change password',
  newPassword: 'New password',
  repeatPassword: 'Repeat password',
  yourEmail: 'Your email',
  tariffsDescription: 'Basic tariffs for analytics, reading and transmission of readings.',
  device: 'Device',
  additionalServices: 'Additional services',
  smsService: 'System sending SMS to\xa0device',
  manualReading: 'Reading readings on a manual command out of schedule',
  partOfTariff: '1/60 of\xa0tariff',
  efficiencyAnalysis: 'Heat efficiency analysis',
  addAccountingObject: 'There are no accounting objects created yet. You can create a new one by clicking +',
  addObject: 'Add object',
  objectParams: 'Object parameters',
  objectsMeteringPoints: 'object metering points',
  historyIsStored: 'The point stores consumption history, so statistics are not reset when meters or transmitter are replaced.',
  pointsNotAdded: 'Points not added',
  addedIsNotActive: 'The function of adding metering points will become active after adding equipment for transmitting readings to this object',
  addMore: 'Add more',
  priceForOneObject: '{price, number, ::currency/RUB}\nper\xa0accounting\xa0object'
}

export const userPageEn = createTranslationsWithSpaceName(translations, spaceName)