import {FormattedMessage} from "react-intl"
import React from "react"
import {NavLink} from "react-router-dom";

function TabBar({text, onClick, link, onlyOne = false}){
  return (
    <NavLink className={`main-tabs__item ${onlyOne ? 'main-tabs__item--one' : ''}`} onClick={onClick} to={link}>
      <h1 className={'title title_h2'}>
        <FormattedMessage id={text}/>
      </h1>
    </NavLink>
  )
}

export default TabBar
