import Chat from "@/presentation/components/chat/Chat";
import React, {useEffect, useRef, useState} from "react";
import Logo from "@/presentation/components/logo/Logo";
import StoreBlock from "@/presentation/components/mainPage/components/StoreBlock";
import Tabs from "@/presentation/components/mainPage/components/Tabs";
import SearchAddressInput from "@/presentation/components/mainPage/components/SearchAddressInput";
import Account from "@/presentation/components/account/Account";
import cookiesManager from "@/internal/lib/services/cookiManager";
import {userDataSelectors} from "@/internal/lib/storeModels/models/user/userDataModel";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Loader from "@/presentation/components/loader/Loader";
import UserPage from "@/presentation/components/userPage/UserPage";
import Loading from "@/presentation/components/loading/Loading";
import {mkdsEndpoint} from "@/data/api_entities/mkds/mkds_endpoint";
import OwnerMKDSList from "@/presentation/components/pages/OwnerMainPage/components/MKDList/MKDList";
import OwnerMkdPage from "@/presentation/components/pages/OwnerMainPage/components/OwnerMkdPage/OwnerMkdPage";

const OwnerMainPage = () => {
  const navigate = useNavigate()
  const userData = userDataSelectors.userData.useValue()
  const getUserData = userDataSelectors.userData.useGetUserData()
  const [isFirstLoading, setIsFirstLoading] = useState(true)
  const userDataLoading = userDataSelectors.loadingUserData.useValue()

  useEffect(() => {
    if (userData.id == null) {
      getUserData()
    }

    if (!!cookiesManager.get('X-pulse')) {
      cookiesManager.set('X-pulseAddress', '')
    } else {
      navigate('/input', {
        replace: true,
      })
    }
  }, [])

  useEffect(() => {
    if (isFirstLoading && !userDataLoading) {
      setIsFirstLoading(false)
    }
  }, [userDataLoading])

  return (
    <Loading loading={isFirstLoading}>
      <_Page />
    </Loading>
  )
}

const _Page = () => {
  const texts = [['Расчет теплопотребления','heat_calculation/list']]
  const [mode, setMode] = useState(0)
  const navigate = useNavigate()
  const location = useLocation()
  const [loadingMKDS, setLoadingMKDS] = useState(false);
  const userData = userDataSelectors.userData.useValue()

  useEffect(() => {
    if (!userData.owner_apartment) {
      navigate('/', {
        replace: true
      });
    }
  }, [userData.owner_apartment])


  useEffect(() => {
    if (location?.pathname === '/owner' || location?.pathname === '/owner/heat_calculation') {
      navigate('heat_calculation/list')
    }
  }, [])

  return (
    <div className={'main-page'}>
      <Chat/>
      <div className="main-page__header">
        <div className="main-page__header-left">
          <div className={'main-page__logo-block'}>
            <Logo />
            <StoreBlock />
          </div>

          <Tabs
            tabs={texts}
            setMode={setMode}
          />
        </div>

        <Account />
      </div>

      <Loader loading={loadingMKDS} styles={{
        wrapper: (base) => ({
          ...base,
          display: 'flex',
          flex: '3 1',
          height: '100%',
          // width: '100%',
          maxWidth: '100%',
          overflow: 'auto',
          // flex: 3,
          paddingRight: loadingMKDS
            ? '0.625rem'
            : ''
        })
      }}>
        <div className="main-page__content">
          <Routes>
            <Route
              path={'user_page/*'}
              element={<UserPage />}
            />

            <Route
              path={'heat_calculation/list'}
              element={<OwnerMKDSList />}
            />


            <Route
              path={'heat_calculation/list/:id/*'}
              element={<OwnerMkdPage />}
              />
          </Routes>
        </div>
      </Loader>
    </div>
  )
}

export default OwnerMainPage;
