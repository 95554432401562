import {useParams} from "react-router-dom";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import React, {useEffect, useRef, useState} from "react";
import {enumeratorEndpoint} from "@/data/api_entities/enumerator/enumartaor_endpoint";
import {FormattedMessage} from "react-intl";
import Loader from "@/presentation/components/loader/Loader";
import Input from "@/presentation/components/common/formComponents/Input";
import {
  enumeratorIndicationsFormController
} from "@/presentation/components/pages/OwnerMainPage/components/OwnerEnumerator/form.controller";
import {selectors} from "@/presentation/shared/ui/message";
import HoveredPopover from "@/presentation/components/common/HoveredPopover";
import {useNavigateBack} from "@/internal/custom_hooks/navigateBack";
import {MKDHelper} from "@/presentation/components/heatCalculationPage/helpers";

const OwnerEnumerator = ({toggleAlertTrigger}) => {
  const [loading, setLoading] = useState(true)
  const getDataRequestController = useRef()
  const {id} = useParams()
  const setMessage = selectors.useSetMessage()
  const setInitData = enumeratorIndicationsFormController.useSetInitFormData();
  const onClickSubmit = enumeratorIndicationsFormController.useOnClickSubmit();
  const resetForm = enumeratorIndicationsFormController.useResetForm();
  const setOnValidSubmit = enumeratorIndicationsFormController.useSetOnValidSubmit();
  const isValidForm = enumeratorIndicationsFormController.useIsFormValid()
  const [enumeratorData, setEnumeratorData] = useState(null)
  const [navigateBack] = useNavigateBack()

  const handleValidSubmit = (data) => {
    setLoading(true)
    const {promise} = enumeratorEndpoint.change({
      indication: data.indication
    }, id)

    promise
      .then((res) => {
        if ('id' in res) {
          setMessage({
            header: 'Сохранение показаний',
            message: 'Показания ОП успешно сохранены',
            type: 'success'
          })
          toggleAlertTrigger();
          navigateBack();
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось сохранить показания ОП',
            type: 'error',
            count: 3000
          })
        }
      }).catch(() => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось сохранить показания ОП',
          type: 'error',
          count: 3000
        })
    }).finally(() => setLoading(false))
  }

  const handleSubmit = () => {
    if (!isValidForm) return;

    onClickSubmit();
  }

  useEffect(() => {
    setOnValidSubmit(handleValidSubmit)
  }, [id]);

  useEffect(() => {
    return () => {
      resetForm();
    }
  }, [id]);

  useEffect(() => {
    return () => {
      getDataRequestController.current?.abort()
    }
  }, [])

  useEffect(() => {
    if (id) {
      setLoading(true)
      getDataRequestController.current?.abort()

      const {promise, controller} = enumeratorEndpoint.getById(id)
      getDataRequestController.current = controller
      promise
        .then(res => {
          if ('id' in res) {
            setEnumeratorData(res)
            setInitData({indication: res?.indication ?? ''})
          } else {
            setMessage({
              header: <FormattedMessage id={'requests.error'}/>,
              message: 'Не удалось получить данные ОП',
              type: 'error',
              count: 3000
            })
          }
          setLoading(false)
        })
        .catch(err => {
          if (err.name === 'AbortError') return
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось получить данные ОП',
            type: 'error',
            count: 3000
          })
          setLoading(false)
        })
    }
  }, [id])

  return (
    <div className={'enumerator-form'}>
      <ArrowBack
        pathOnVisible={id}
      />

      <Loader loading={loading}>
        <div className="enumerator-form__title-wrapper">
          <h2 className="title">
            Параметры распределителя №{enumeratorData?.number ?? ''}
          </h2>

          <p style={{
            fontSize: '14px',
            color: '#36455D',
            marginBottom: '16px',
            paddingTop: '8px'
          }}>
            При отсутствии показаний Вы можете ввести их вручную со 2-го числа 10:00 до 5-го числа 00:00
          </p>
        </div>
        <div className="form-fields">
         <HoveredPopover disabled={!enumeratorData?.changed_admin} text={'Показания введены сервисменом'}>
           <Input
             formSelectors={enumeratorIndicationsFormController}
             name={'indication'}
             label={<span>Показания распределителя №{enumeratorData?.number ?? ''} на {MKDHelper.dateFormat(enumeratorData?.date)}</span>}
             valueFormatter={(value) => !!value.length ? +value : value}
             type={'number'}
             disabled={!enumeratorData?.enumerator_editable}
             additionalProps={{
               style: {
                 background: enumeratorData?.changed_admin ? 'yellow' : enumeratorData?.enumerator_editable ? 'unset' : 'rgba(239, 239, 239, 0.3)'
               }
             }}
           />
         </HoveredPopover>
        </div>

        {!enumeratorData?.changed_admin && <div className="enumerator-form__btns">
          <button
            className="btn"
            disabled={!isValidForm || loading || !enumeratorData?.enumerator_editable}
            onClick={handleSubmit}
          >
            <FormattedMessage id={'app.save'}/>
          </button>
        </div>}
      </Loader>
    </div>
  )
}

export default OwnerEnumerator;
