import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'userPage'
const translations = {
  'personalData':'Личный кабинет',
  'analytics':'Аналитика',
  'logOut': 'Выйти из системы',
  'deleteAcc': 'Удалить аккаунт',
  'balanceAndPoints': 'Баланс – {bonus, number} баллов и {balance, number, ::currency/RUB}',
  'accountDeleted': 'Ваша учетная запись удалена',
  accAndRates: 'Счет и тарифы',
  myData: 'Мои данные',
  accountingObjects: 'Объекты учета',
  usersList: 'Клиенты',
  accesses: 'Доступы',
  'deleteAcc?': 'Удалить аккаунт?',
  requisites: 'Реквизиты',
  personalAccount: 'Лицевой счет',
  howCanICallU: 'Как к вам обращаться',
  tellNotReq: 'Телефон (необязательно)',
  historyTransaction: 'История транзакций',
  tariffs: 'Тарифы',
  balance: 'Баланс',
  topUp: 'Пополнить',
  subscription: 'Ваша подписка',
  onMonth: 'на 30 дней',
  monthTotal: 'Итого за 30 дней:',
  topUping: 'Пополнение',
  writeOff: 'Списание',
  writeOffHistory: 'История списаний',
  keepHistory: 'Мы храним историю всех транзакций за последние 3 года.',
  latestTransactions: 'Последние транзакции',
  noTransactions: 'Нет совершенных транзакций',
  topUpAccount: 'Пополнить счет',
  bankCard: '1. Банковской картой',
  byPlatron: 'Через Platron',
  paymentAmount: 'Сумма оплаты',
  changeEmail: 'Изменить email',
  enterCode: 'Введите код',
  ifNoMail: 'Если письма нет, проверьте корректность введенного адреса и повторите попытку',
  ifChangeMail: 'Если изменить e-mail, на него прийдет письмо с подтверждением. Если его проигнорировать, e-mail не будет изменен',
  sendCode: 'Отправить код',
  legalEntityName: 'Название юридического лица',
  legalAddress: 'Юридический адрес',
  actualAddress: 'Фактический адрес',
  ifActualIsLegal: 'Если Фактический адрес совпадает с  Юридическим адресом',
  contactCommunication: 'Контакт для связи',
  inn: 'ИНН',
  changePassword: 'Изменить пароль',
  newPassword: 'Новый пароль',
  repeatPassword: 'Повторить пароль',
  yourEmail: 'Ваш email',
  tariffsDescription: 'Базовые тарифы на аналитику, считывание и передачу показаний.',
  device: 'Устройство',
  additionalServices: 'Дополнительные услуги',
  smsService: 'Отправка Системой СМС на\xa0устройство',
  manualReading: 'Считывание показаний по ручной команде вне расписания',
  partOfTariff: '1/60 от\xa0тарифа',
  efficiencyAnalysis: 'Анализ эффективности теплопотребления',
  addAccountingObject: 'Пока нет созданных объектов учета. Вы можете создать новый нажав на +',
  addObject: 'Добавить объект',
  objectParams: 'Параметры объекта',
  objectsMeteringPoints: 'точки учета на объекте',
  historyIsStored: 'В точке хранится история потребления, поэтому статистика не сбрасывается, при замене счетчиков или передатчика.',
  pointsNotAdded: 'Точки учета не добавлены',
  addedIsNotActive: 'Функция добавления точек учета станет активной после добавления оборудования для передачи показаний к данному объекту',
  addMore: 'Добавить еще',
  priceForOneObject: '{price, number, ::currency/RUB}\nза\xa0объект учета'
}

export const userPageRu = createTranslationsWithSpaceName(translations, spaceName)
