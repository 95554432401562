import {Route, Routes, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {useNavigateBack} from "@/internal/custom_hooks/navigateBack";
import {mkdsEndpoint} from "@/data/api_entities/mkds/mkds_endpoint";
import {FormattedMessage} from "react-intl";
import {selectors} from "@/presentation/shared/ui/message";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import {MKDHelper} from "@/presentation/components/heatCalculationPage/helpers";
import {
  OWNER_MKD_PAGE_TABS
} from "@/presentation/components/pages/OwnerMainPage/components/OwnerMkdPage/OwnerMkdPage.constants";
import LinkTab from "@/presentation/components/common/LinkTab";
import Loader from "@/presentation/components/loader/Loader";
import {PATHS} from "@/presentation/components/heatCalculationPage/components/MKDPage/const";
import OwnerApartmentsPage
  from "@/presentation/components/pages/OwnerMainPage/components/OwnerApartmentsPage/OwnerApartmentsPage";
import AlertTooltip from "@/presentation/components/pages/OwnerMainPage/components/AlertTooltip/AlertTooltip";
import {useToggle} from "@/internal/custom_hooks/useToggle";

const OwnerMkdPage = () => {
  const {id} = useParams();
  const [loadingMKD, setLoadingMKD] = useState(true)
  const getMKDRequestController = useRef()
  const [navigateBack] = useNavigateBack()
  const [data, setData] = useState(null)
  const setMessage = selectors.useSetMessage()
  const [updateAlertTrigger, toggleAlertTrigger] = useToggle()

  const getMkd = async () => {
    if (!id) {
      setLoadingMKD(false)
      return;
    }
    try {
      setLoadingMKD(true)
      getMKDRequestController.current?.abort();
      const {promise, controller} = mkdsEndpoint.getById(id)
      getMKDRequestController.current = controller;
      const res = await promise;
      if (res.id) {
        setData(res)
      } else {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось загрузить данные о доме',
          type: 'error',
          count: 3000
        })
      }
      setLoadingMKD(false)
    } catch (err) {
      if (err.name === 'AbortError') return
      setLoadingMKD(false)
      setMessage({
        header: <FormattedMessage id={'requests.error'}/>,
        message: 'Не удалось загрузить данные о доме',
        type: 'error',
        count: 3000
      })
    }
  }

  useEffect(() => {
    return () => {
      getMKDRequestController.current?.abort()
    }
  }, [])

  useEffect(() => {
    getMkd()
  }, [id, updateAlertTrigger])

  return (
    <div className={'mkd-page'}>
      <div className="mkd-page__column">
        <ArrowBack
          pathOnVisible={'list'}
          withId={true}
        />
        <Loader loading={loadingMKD}>
          <div className="mkd-page__title-wrapper">
            <h2 className="title">{MKDHelper.getAddress(data)}</h2>
            <span>{MKDHelper.getRegion(data)}</span>
          </div>

          {OWNER_MKD_PAGE_TABS.map(tab => {
            return (
              <LinkTab
                key={tab.id}
                path={`${tab.path}`}
                text={tab.textId}
                extraComponent={data?.enumerator_editable ? <div style={{
                  position: "absolute",
                  top: '50%',
                  transform: 'translateY(-50%)',
                  right: '3rem'
                }}>
                  <AlertTooltip/>
                </div> : null}
              />
            )
          })}
        </Loader>
      </div>

      <Routes>
        <Route
          path={`${PATHS.apartments}/*`}
          element={<OwnerApartmentsPage updateAlertTrigger={updateAlertTrigger} toggleAlertTrigger={toggleAlertTrigger} data={data}/>}
        />
      </Routes>
    </div>
  )
}

export default OwnerMkdPage;