import React, {useState} from "react";
import {selectors} from "@/presentation/shared/ui/message";
import {FormattedMessage} from "react-intl";
import ownerApartmentEndpoints from "@/data/api_entities/owner_apartment/owner_apartment.endpoints";

const RegeneratePassword = ({ownerId, onLoading}) => {
  const [loading, setLoading] = useState(false);
  const setMessage = selectors.useSetMessage()

  const handleSetLoading = (value) => {
    setLoading(value)
    if (onLoading) onLoading(value)
  }

  const handleClick = async () => {
    if (loading || !ownerId) return;

    try {
      handleSetLoading(true)

      const {promise} = ownerApartmentEndpoints.regeneratePassword(ownerId)
      const res = await promise;
      if (res?.status === 'ok') {
        setMessage({
          message: 'Пароль успешно изменен и отправлен на почту',
          header: 'Изменение пароля',
          type: 'success',
          count: 3000
        })
      } else {
        setMessage({
          message: 'Не удалось изменить пароль',
          header: <FormattedMessage id={'requests.error'} />,
          type: 'error',
          count: 3000
        })
      }
      handleSetLoading(false)
    } catch (err) {
      setMessage({
        message: 'Не удалось изменить пароль',
        header: <FormattedMessage id={'requests.error'} />,
        type: 'error',
        count: 3000
      })
    } finally {
      handleSetLoading(false)
    }
  }

  return (
    <div className={'owner-card__regenerate'}>
      <button disabled={loading} onClick={handleClick} className={'btn'}>Отправить новый пароль</button>
    </div>
  )
}

export default RegeneratePassword;
