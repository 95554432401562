import {mkdsStateSelectors} from "@/internal/lib/storeModels/models/mkds/mkdsState";
import {MKDHelper} from "@/presentation/components/heatCalculationPage/helpers";
import {MKD_PAGE_TABS, PATHS} from "@/presentation/components/heatCalculationPage/components/MKDPage/const";
import LinkTab from "@/presentation/components/common/LinkTab";
import {Route, Routes, useNavigate, useParams} from "react-router-dom";
import MKDForm from "@/presentation/components/heatCalculationPage/components/MKDForm";
import {changeMKDFormStateSelectors} from "@/internal/lib/storeModels/models/mkds/forms/mkds_forms";
import {mkdsEndpoint} from "@/data/api_entities/mkds/mkds_endpoint";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useNavigateBack} from "@/internal/custom_hooks/navigateBack";
import ApartmentsPage from "@/presentation/components/heatCalculationPage/components/MKDPage/components/ApartmentsPage";
import CalculationsPage from "@/presentation/components/heatCalculationPage/components/MKDPage/components/CalculationsPage";
import {selectors} from "@/presentation/shared/ui/message";
import {FormattedMessage} from "react-intl";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import AlertTooltip from "@/presentation/components/pages/OwnerMainPage/components/AlertTooltip/AlertTooltip";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import Loader from "@/presentation/components/loader/Loader";

const MKDPage = () => {
  const params = useParams()
  const {id: selectedId} = params
  const [selectedObject, setSelectedObject] = useState()
  const [loading, setLoading] = useState(true)
  const getMKDDataRequestController = useRef()
  const [isChanging, setIsChanging] = useState(false)
  const [navigateBack] = useNavigateBack()
  const resetApartmentsList = mkdsStateSelectors.apartmentsList.useReset()
  const setMessage = selectors.useSetMessage()
  const navigate = useNavigate()
  const updateAlertTrigger = mkdsStateSelectors.updateAlertTrigger.useValue()
  const setSelectedMkd = mkdsStateSelectors.selectedMKD.useSetValue()

  const getMKDData = useCallback(() => {
    setSelectedMkd(selectedId)
    if (selectedId) {
      setLoading(true)
      getMKDDataRequestController.current?.abort()
      const {promise, controller} = mkdsEndpoint.getById(selectedId)
      getMKDDataRequestController.current = controller

      promise
        .then(res => {
          if ('id' in res) {
            setSelectedObject(res)
          } else {
            setMessage({
              header: <FormattedMessage id={'requests.error'}/>,
              message: 'Не удалось получить данные о МКД',
              type: 'error',
              count: 3000
            })
          }
          setLoading(false)
        })
        .catch(err => {
          if (err.name === 'AbortError') return
          setLoading(false)
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось данные о МКД',
            type: 'error',
            count: 3000
          })
        })
    }
  }, [selectedId])

  useEffect(() => {
    getMKDData();
  }, [getMKDData, updateAlertTrigger]);

  useEffect(() => {
    if (!selectedId) {
      navigate('/user/heat_calculation/list', {
        replace: true
      })
    }
  }, [selectedId])

  useEffect(() => {
    return () => {
      resetApartmentsList()
      getMKDDataRequestController.current?.abort()
    }
  }, [])

  const onValidSubmitChangeMKD = (data) => {
    setIsChanging(true)
    const {promise} = mkdsEndpoint.change(data, selectedObject?.id)

    promise
      .then(res => {
        if ('id' in res) {
          if (res.id == selectedId) {
            getMKDData();
          }
          setMessage({
            header: 'Изменение МКД',
            message: 'МКД успешно изменен',
            type: 'success',
            count: 3000
          })
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось изменить МКД',
            type: 'error',
            count: 3000
          })
          console.log(res)
        }
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось изменить МКД',
          type: 'error',
          count: 3000
        })
      })
      .finally(() => setIsChanging(false))
  }

  return (
    <div className={'mkd-page'}>
      <div className="mkd-page__column">
        <ArrowBack
          pathOnVisible={'list'}
          withId={true}
        />

        <Loader loading={loading} >
          <div className="mkd-page__title-wrapper">
            <h2 className="title">{MKDHelper.getAddress(selectedObject)}</h2>
            <span>{MKDHelper.getRegion(selectedObject)}</span>
          </div>

          {MKD_PAGE_TABS.map(tab => {
            let extraComponent;

            if (tab.path === PATHS.apartments && selectedObject?.enumerator_editable) {
              extraComponent = <div style={{
                position: "absolute",
                top: '50%',
                transform: 'translateY(-50%)',
                right: '3rem'
              }}>
                <AlertTooltip/>
              </div>
            } else if (tab.path === PATHS.settings && selectedObject?.mansion_editable) {
              extraComponent = <div style={{
                position: "absolute",
                top: '50%',
                transform: 'translateY(-50%)',
                right: '3rem'
              }}>
                <AlertTooltip text={'Не указано потребление МКД'}/>
              </div>
            }
            return (
              <LinkTab
                key={tab.id}
                path={`${tab.path}`}
                text={tab.textId}
                extraComponent={extraComponent}
              />
            )
          })}
        </Loader>
      </div>

      <Routes>
        <Route
          path={`${PATHS.settings}/*`}
          element={<MKDForm
            formStateSelectors={changeMKDFormStateSelectors}
            editId={selectedObject?.id}
            classPostfix={'mkd-page__column'}
            pathOnVisible={PATHS.settings}
            onValidSubmit={onValidSubmitChangeMKD}
            isLoading={isChanging}
          />}
        />

        <Route
          path={`${PATHS.apartments}/*`}
          element={<ApartmentsPage selectedObject={selectedObject}/>}
        />

        <Route
          path={`${PATHS.calculations}/*`}
          element={<CalculationsPage />}
        />
      </Routes>
    </div>
  )
}

export default MKDPage
