import FindOwner
  from "@/presentation/components/heatCalculationPage/components/MKDPage/components/ApartmentOwnerPage/components/FindOwner";
import {useState} from "react";
import Loader from "@/presentation/components/loader/Loader";

const EmptyOwner = ({onOwnerChange, onRegisterOwner}) => {
  const [newOwner, setNewOwner] = useState(null)
  const [isAdding, setIsAdding] = useState(false)

  const handleSelect = (option) => {
    setNewOwner(option.value)
  }

  const handleRegister = async (data) => {
    try {
      await onRegisterOwner(data)
    } catch (e) {
      console.log(e)
    }
  }

  const handleAddOwner = async () => {
    try {
      setIsAdding(true)

      if (onOwnerChange) {
        await onOwnerChange(newOwner)
      }

      setIsAdding(false)
    } catch (e) {
      console.log(e)
    } finally {
      setIsAdding(false)
    }
  }

  return (
   <Loader loading={isAdding}>
     <div className={'empty-owner'}>
       <p>Привяжите уже существующий аккаунт или зарегистрируйте новый</p>

       <FindOwner onSelect={handleSelect} onRegisterNewOwner={handleRegister}/>

       <button onClick={handleAddOwner} className={'btn'} disabled={!newOwner || isAdding}>Добавить</button>
     </div>
   </Loader>
  )
}

export default EmptyOwner
