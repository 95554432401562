import ArrowBack from "@/presentation/components/common/ArrowBack";
import {PATHS} from "@/presentation/components/heatCalculationPage/components/MKDPage/const";
import React, {useCallback, useEffect, useRef, useState} from "react";
import EmptyOwner
  from "@/presentation/components/heatCalculationPage/components/MKDPage/components/ApartmentOwnerPage/components/EmptyOwner";
import OwnerCard
  from "@/presentation/components/heatCalculationPage/components/MKDPage/components/ApartmentOwnerPage/components/OwnerCard";
import {selectors} from "@/presentation/shared/ui/message";
import {FormattedMessage} from "react-intl";
import ownerApartmentEndpoints from "@/data/api_entities/owner_apartment/owner_apartment.endpoints";
import Loader from "@/presentation/components/loader/Loader";

const ApartmentOwnerPage = ({selectedApartment}) => {
  const [owner, setOwner] = useState(null)
  const setMessage = selectors.useSetMessage()
  const [loading, setLoading] = useState(true)
  const getOwnerRequestSignal = useRef(null)

  useEffect(() => {
    return () => {
      getOwnerRequestSignal.current?.abort()
    }
  }, [])

  const getOwnerData = useCallback(async () => {
    if (!selectedApartment?.id) return;

    getOwnerRequestSignal.current?.abort();
    setLoading(true)
    const {promise, controller} = ownerApartmentEndpoints.getOwnerById(selectedApartment.id)
    getOwnerRequestSignal.current = controller;

    try {
      const res = await promise;
      if (res.email) {
        setOwner(res)
      } else if (res.detail === 'not found apartment') {
        setOwner(null)
      }
      setLoading(false)
    } catch (err) {
      if (err.name === 'AbortError') return
      setLoading(false)
      setMessage({
        message: 'Не удалось получить данные о собственнике квартиры',
        header: <FormattedMessage id={'requests.error'} />,
        type: 'error',
        count: 3000
      })
    }

  }, [selectedApartment])

  useEffect(() => {
     getOwnerData()
  }, [getOwnerData])

  const handleChangeOwner = async (newOwner) => {
    try {
      if (!selectedApartment?.id) return;
      const {promise} = ownerApartmentEndpoints.changeOwnerById({body: {user_id: newOwner?.id}, id: selectedApartment.id})

      const res = await promise;

      if (res.user_id) {
        setMessage({
          message: 'Собсвтенник успешно изменен',
          header: 'Изменение собственника квартиры',
          type: 'success',
          count: 3000
        })
        getOwnerData()
      } else {
        setMessage({
          message: 'Не удалось изменить собственника квартиры',
          header: <FormattedMessage id={'requests.error'} />,
          type: 'error',
          count: 3000
        })
      }
    } catch (err) {
      setMessage({
        message: 'Не удалось изменить собственника квартиры',
        header: <FormattedMessage id={'requests.error'} />,
        type: 'error',
        count: 3000
      })
    }
  }

  const handleBindOwner = async (newOwner) => {
    if (!selectedApartment?.id) return
    try {
      const {promise} = ownerApartmentEndpoints.bindOwnerToApartment({apartmentId: selectedApartment?.id, ownerId: newOwner.id})

      const res = await promise;

      if (res.user_id) {
        setMessage({
          message: 'Собсвтенник успешно привязан',
          header: 'Изменение собственника квартиры',
          type: 'success',
          count: 3000
        })
        getOwnerData()
      } else {
        setMessage({
          message: 'Не удалось привязать собсвтенника к квартире',
          header: <FormattedMessage id={'requests.error'} />,
          type: 'error',
          count: 3000
        })
      }
    } catch (err) {
      setMessage({
        message: 'Не удалось привязать собсвтенника к квартире',
        header: <FormattedMessage id={'requests.error'} />,
        type: 'error',
        count: 3000
      })
    }
  }

  const handleRegisterOwner = async (data) => {
    try {
      console.log(selectedApartment)
      if (!selectedApartment?.id) return
      const {promise} = ownerApartmentEndpoints.createAndBindOwnerToApartment({
        body: {
          email: data.email.trim()
        },
        apartmentId: selectedApartment.id
      })

      const res = await promise;

      if (res.user_id) {
        setMessage({
          message: 'Собсвтенник успешно зарегистрирован и привязан к квартире',
          header: 'Регистрация собственника квартиры',
          type: 'success',
          count: 3000
        })
        getOwnerData()
      } else if (res?.warning?.includes('already exists')) {
        setMessage({
          message: 'Собсвтенник с таким email уже зарегистрирован',
          header: <FormattedMessage id={'requests.error'} />,
          type: 'error',
          count: 3000
        })

      } else {
        setMessage({
          message: 'Не удалось зарегистрировать и привязать собственника к квартире',
          header: <FormattedMessage id={'requests.error'} />,
          type: 'error',
          count: 3000
        })
      }
    } catch (err) {
      setMessage({
        message: 'Не удалось зарегистрировать и привязать собственника к квартире',
        header: <FormattedMessage id={'requests.error'} />,
        type: 'error',
        count: 3000
      })
    }
  }

  const handleDeleteOwner = async () => {
    try {
      if (!selectedApartment?.id) return
      const {promise} = ownerApartmentEndpoints.deleteOwnerById(selectedApartment.id)
      const res = await promise;

      if (res.status === 'ok') {
        setMessage({
          message: 'Собственник квартиры успешно отвязан',
          header: 'Регистрация собственника квартиры',
          type: 'success',
          count: 3000
        })
        getOwnerData()
      } else {
        setMessage({
          message: 'Не удалось отвязать собственника от квартиры',
          header: <FormattedMessage id={'requests.error'} />,
          type: 'error',
          count: 3000
        })
      }

    } catch (err) {
      setMessage({
        message: 'Не удалось отвязать собственника от квартиры',
        header: <FormattedMessage id={'requests.error'} />,
        type: 'error',
        count: 3000
      })
    }
  }

  return (
    <div className={'apartment-owner-page'}>
      <ArrowBack
        pathOnVisible={PATHS.owner}
      />

     <Loader loading={loading}>
       <h2 className="title">
         Собственник квартиры {selectedApartment?.kv ?? ''}
       </h2>

       {!!owner ?
         <OwnerCard
           owner={owner}
           onOwnerChange={handleChangeOwner}
           selectedApartment={selectedApartment}
           onRegisterOwner={handleRegisterOwner}
           onDelete={handleDeleteOwner}
         />
         : <EmptyOwner onOwnerChange={handleBindOwner} getOwnerData={getOwnerData}  onRegisterOwner={handleRegisterOwner}/>}
     </Loader>
    </div>
  )
}

export default ApartmentOwnerPage
