import FindOwnerSelect
  from "@/presentation/components/heatCalculationPage/components/MKDPage/components/ApartmentOwnerPage/components/FindOwnerSelect";
import React, {useEffect, useRef, useState} from "react";
import ownerApartmentEndpoints from "@/data/api_entities/owner_apartment/owner_apartment.endpoints";
import {selectors} from "@/presentation/shared/ui/message";
import {FormattedMessage} from "react-intl";

const FindOwner = ({onSelect, onRegisterNewOwner}) => {
  const [isLoading, setIsLoading] = useState(false);
  const requestControllerRef = useRef(null)
  const [options, setOptions] = useState([])
  const [ownerOption, setOwnerOption] = useState(null)
  const setMessage = selectors.useSetMessage()

  const handleChange = (newValue) => {
    setOwnerOption(newValue)
    if (onSelect) {
      onSelect(newValue)
    }
  }

  useEffect(() => {
    handleSearch('').catch(() => {});
  }, [])

  const handleSearch = async (value) => {
    const trimmedValue = value.trim()
    try {
      setIsLoading(true);
      requestControllerRef.current?.abort();
      const {promise, controller} = ownerApartmentEndpoints.searchOwners(trimmedValue)
      requestControllerRef.current = controller;
      const res = await promise
      if (Array.isArray(res)) {
        setOptions(res.map(data => ({
          value: data,
          label: <div className={'find-owner-select-value'}>
            <span>{data.email}</span>
          </div>,
        })))
      } else {
        setMessage({
          message: 'Не удалось получить список собственников',
          header: <FormattedMessage id={'requests.error'} />,
          type: 'error',
          count: 3000
        })
      }
      setIsLoading(false)
    } catch (err) {
      if (err.name === 'AbortError' || err.message === 'AbortError') return;
      requestControllerRef.current = null
      setMessage({
        message: 'Не удалось получить список собственников',
        header: <FormattedMessage id={'requests.error'} />,
        type: 'error',
        count: 3000
      })
      setIsLoading(false)
    }
  }

  return (
    <div className={'form-group'}>
      <label>Поиск собственника</label>
      <FindOwnerSelect
        value={ownerOption ? {
          value: ownerOption.value,
          label: <div className={'find-owner-select-value'}>
            <span>{ownerOption.value.email}</span>
          </div>
        } : null}
        onChange={handleChange}
        isLoading={isLoading}
        options={options}
        onSearch={handleSearch}
        onRegister={onRegisterNewOwner}
      />
    </div>
  )
}

export default FindOwner;