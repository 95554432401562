import {request} from "@/internal/lib/transport/request";
import {APARTMENTS_PAGE_SIZE} from "@/data/api_entities/const";

export const mkdsEndpoint = {
  getAll() {
    const params = {
      url: 'api/heat_mansions/?page=1&limit=1000',
      method: 'GET'
    }

    return request(params)
  },
  create(data) {
    const params = {
      url: 'api/heat_mansions/',
      method: 'POST',
      data: {
        ...data,
        odn: +data.odn,
        device_type: data.device_type.id,
        energy_period: data.energy_period ? +data.energy_period : null
      }
    }

    return request(params)
  },
  change(data, id) {
    const params = {
      url: `api/heat_mansions/${id}/`,
      method: 'PUT',
      data: {
        ...data,
        odn: +data.odn,
        device_type: data.device_type.id,
        energy_period: data.energy_period ? +data.energy_period : null
      }
    }

    return request(params)
  },
  getById(id) {
    const params = {
      url: `api/heat_mansions/${id}/`,
      method: 'GET'
    }

    return request(params)
  },
  delete(id) {
    const params = {
      url: `api/heat_mansions/${id}/`,
      method: 'DELETE'
    }

    return request(params)
  },
  getApartments(id, page) {
    const query = page
      ? `?page=${page}&limit=${APARTMENTS_PAGE_SIZE}`
      : ''
    const params = {
      url: `api/heat_mansions/${id}/apartment/${query}`,
      method: 'GET'
    }

    return request(params)
  },
  searchApartments(id, query) {
    const params = {
      url: `api/heat_mansions/${id}/search_apartment/`,
      method: 'POST',
      data: {
        query
      }
    }

    return request(params)
  },
  getDevicesTypes() {
    const params = {
      url: `api/heat_type_op/`,
      method: 'GET'
    }

    return request(params)
  }
}
