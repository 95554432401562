import Logo from "@/presentation/components/logo/Logo";
import StoreBlock from "@/presentation/components/mainPage/components/StoreBlock";
import SearchAddressInput from "@/presentation/components/mainPage/components/SearchAddressInput";
import AsideForm from "@/presentation/components/mainPage/components/AsideForm";
import Loader from "@/presentation/components/loader/Loader";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import Chat from "@/presentation/components/chat/Chat";
import React, {useEffect, useState} from "react";
import cookiesManager from "@/internal/lib/services/cookiManager";
import {useNavigate, Route, Routes, useLocation} from "react-router-dom";
import {devicesSelectors} from "@/internal/lib/storeModels/models/devices/devicesModel";
import {meteringPointsPageSelectors} from "@/internal/lib/storeModels/models/meteringPointsPage/meteringPointsModels";
import Analytics from "@/presentation/components/analytics/Analytics";
import UserPage from "@/presentation/components/userPage/UserPage";
import RoutePaths from "@/route_paths";
import {userDataSelectors} from "@/internal/lib/storeModels/models/user/userDataModel";
import {ACCOUNTING_OBJECTS_PATH,} from "@/presentation/components/userPage/const";
import Tabs from "@/presentation/components/mainPage/components/Tabs";
import Account from "@/presentation/components/account/Account";
import Loading from "@/presentation/components/loading/Loading";
import ObjectsList from "@/presentation/components/mainPage/components/ObjectsList";
import HeatCalculationPage from "@/presentation/components/heatCalculationPage/HeatCalculationPage";
import {mkdsStateSelectors} from "@/internal/lib/storeModels/models/mkds/mkdsState";

const MainPage = () => {
  const navigate = useNavigate()
  const setSelectedAddress = addressesSelectors.selectedAddress.useSetValue()
  const getAddresses = addressesSelectors.addresses.useGetAddresses()
  const getUserData = userDataSelectors.userData.useGetUserData()
  const userDataLoading = userDataSelectors.loadingUserData.useValue()
  const userData = userDataSelectors.userData.useValue()
  const resetDevices = devicesSelectors.devices.useReset()
  const resetPoints = meteringPointsPageSelectors.pointsList.useReset()

  const [isFirstLoading, setIsFirstLoading] = useState(true)

  useEffect(() => {
    resetDevices()
    resetPoints()
    if (userData.id == null) {
      getUserData()
    }
    getAddresses()

    if (!!cookiesManager.get('X-pulse')) {
      cookiesManager.set('X-pulseAddress', '')
      setSelectedAddress(null)
    } else {
      navigate('/input', {
        replace: true,
      })
    }
  }, [])

  useEffect(() => {
    if (isFirstLoading && !userDataLoading) {
      setIsFirstLoading(false)
    }
  }, [userDataLoading])

  return (
    <Loading loading={isFirstLoading}>
      <_Page />
    </Loading>
  )
}

const _Page = () => {
  const texts = [['userPage.analytics','analytics'],['userPage.accountingObjects', `${ACCOUNTING_OBJECTS_PATH}`], ['Расчет теплопотребления', 'heat_calculation/list']]
  const [mode, setMode] = useState(0)
  const isLoading = !!addressesSelectors.lastGetAddressesRequest.useValue()
  const userData = userDataSelectors.userData.useValue()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    // if (userData.service_manager === null) {
    //   return
    // }

    switch (true) {
      case userData.service_manager:
        navigate('/service', {
          replace: true
        });
        break;
      case userData.owner_apartment:
        navigate('/owner/heat_calculation/list', {
          replace: true
        });
        break;
      default:
        if (location?.pathname === '/user') {
          navigate('analytics')
        }
        break;

    }
    // if (userData.service_manager === true) {
    //   navigate('/service', {
    //     replace: true
    //   })
    // } else if (userData.service_manager == false) {
    //   if (location?.pathname === '/user') {
    //     navigate('analytics')
    //   }
    // }
  }, [userData.service_manager, userData.owner_apartment])

  return (
    <div className={'main-page'}>
      <Chat/>
      <div className="main-page__header">
        <div className="main-page__header-left">
          <div className={'main-page__logo-block'}>
            <Logo />
            <StoreBlock />
          </div>

          <Tabs
            tabs={texts}
            setMode={setMode}
          />

          <SearchAddressInput isHidden={mode !== 1}/>
        </div>

        <Account />
        {/*<StoreBlock />*/}
      </div>

      <Loader
        loading={isLoading && mode === 1}
        styles={{
          wrapper: (base) => ({
            ...base,
            display: 'flex',
            flex: '3 1',
            height: '100%',
            // width: '100%',
            maxWidth: '100%',
            overflow: 'auto',
            // flex: 3,
            paddingRight: isLoading
              ? '0.625rem'
              : ''
          })
        }}
      >
        <div className="main-page__content">
          <Routes>
            <Route
              path={RoutePaths.analyticsPath}
              element={<Analytics />}
            />

            <Route
              path={`${ACCOUNTING_OBJECTS_PATH}`}
              element={<ObjectsList />}
            />

            <Route
              path={'user_page/*'}
              element={<UserPage />}
            />

            <Route
              path={'heat_calculation/*'}
              element={<HeatCalculationPage />}
            />
          </Routes>
        </div>
      </Loader>
      <AsideForm />
    </div>
  )
}

export default MainPage
