import MKDList from "@/presentation/components/heatCalculationPage/components/MKDList";
import AsideForm from "@/presentation/components/heatCalculationPage/components/AsideForm";
import {useEffect} from "react";
import {mkdsStateSelectors} from "@/internal/lib/storeModels/models/mkds/mkdsState";
import {Route, Routes, useLocation, useParams} from "react-router-dom";
import MKDPage from "@/presentation/components/heatCalculationPage/components/MKDPage/MKDPage";

const HeatCalculationPage = () => {
  const setOpenAside = mkdsStateSelectors.sideForm.useSetValue()

  useEffect(() => {
    return () => {
      setOpenAside(null)
    }
  }, [])

  return (
    <div className={'heat-calculation'}>
      <AsideForm />

      <Routes>
        <Route
          path={'list'}
          element={<MKDList />}
        />

        <Route
          path={'list/:id/*'}
          element={<MKDPage />}
        />
      </Routes>
    </div>
  )
}

export default HeatCalculationPage
