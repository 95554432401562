export const PATHS = {
  apartments: 'apartments',
  calculations: 'calculations'
}

export const OWNER_MKD_PAGE_TABS = [
  {
    id: 1,
    textId: 'Квартиры',
    path: PATHS.apartments
  }
]
