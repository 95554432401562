import {request} from "@/internal/lib/transport/request";

export const ownerApartmentEndpoints = {
  bindOwnerToApartment ({apartmentId, ownerId}) {
    const params = {
      url: 'api/owner_apartment/',
      method: 'POST',
      data: {
        apartment_id: apartmentId,
        user_id: ownerId
      }
    }

    return request(params)
  },

  createAndBindOwnerToApartment ({body, apartmentId}) {
    const params = {
      url: 'api/owner_apartment/create_owner_apertment/',
      method: 'POST',
      data: {
        apartment_id: apartmentId,
        ...body
      }
    }

    return request(params)
  },

  searchOwners (query) {
    const params = {
      url: `api/owner_apartment/search_owner_apertment/?query=${query?.trim()}`,
      method: 'GET'
    }

    return request(params)
  },

  getOwnerById(id) {
    const params = {
      url: `api/owner_apartment/${id}/`,
      method: 'GET'
    }

    return request(params)
  },

  changeOwnerById ({id, body}) {
    const params = {
      url: `api/owner_apartment/${id}/`,
      method: 'PUT',
      data: body
    }

    return request(params)
  },

  deleteOwnerById (id) {
    const params = {
      url: `api/owner_apartment/${id}/`,
      method: 'DELETE'
    }

    return request(params)
  },

  regeneratePassword (ownerId) {
    const params = {
      url: `api/owner_apartment/generate_password/`,
      method: 'POST',
      data: {
        user_id: ownerId
      }
    }

    return request(params)
  },

  getMansions() {
    const params = {
      url: `api/owner_apartment/mansions/`,
      method: 'GET',
    }

    return request(params)
  },

  getMansion(mansionId) {
    const params = {
      url: `api/owner_apartment/${mansionId}/apartments/`,
      method: 'GET',
    }

    return request(params)
  },

  getReportsHistory(apartmentId) {
    const params = {
      url: `api/owner_apartment/${apartmentId}/history_calc/`,
      method: 'GET',
    }

    return request(params)
  },

  getReportUrl(historyId, apartmentId) {
    const params = {
      url: `api/owner_apartment/${historyId}/report/`,
      method: 'POST',
      data: {
        apartment_id: +apartmentId
      },
    }

    return request(params)
  }
}

export default ownerApartmentEndpoints;