import {NavLink, Route, Routes, useParams} from "react-router-dom";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import {PATHS} from "@/presentation/components/heatCalculationPage/components/MKDPage/const";
import {MKDHelper} from "@/presentation/components/heatCalculationPage/helpers";
import LinkTab from "@/presentation/components/common/LinkTab";
import HoveredPopover from "@/presentation/components/common/HoveredPopover";
import Loader from "@/presentation/components/loader/Loader";
import ApartmentForm from "@/presentation/components/heatCalculationPage/components/MKDPage/components/ApartmentForm/ApartmentForm";
import {
  changeApartmentFormSelectors,
  changeEnumeratorFormSelectors,
  newEnumeratorFormSelectors
} from "@/internal/lib/storeModels/models/mkds/forms/mkds_forms";
import React, {useEffect, useState} from "react";
import {apartmentsEndpoint} from "@/data/api_entities/apartments/apartments_endpoint";
import {mkdsStateSelectors} from "@/internal/lib/storeModels/models/mkds/mkdsState";
import {useNavigateBack} from "@/internal/custom_hooks/navigateBack";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import EnumeratorForm from "@/presentation/components/heatCalculationPage/components/MKDPage/components/EnumeratorForm";
import {enumeratorEndpoint} from "@/data/api_entities/enumerator/enumartaor_endpoint";
import {selectors} from "@/presentation/shared/ui/message";
import {FormattedMessage} from "react-intl";
import ApartmentOwnerPage
  from "@/presentation/components/heatCalculationPage/components/MKDPage/components/ApartmentOwnerPage/ApartmentOwnerPage";
import AlertTooltip from "@/presentation/components/pages/OwnerMainPage/components/AlertTooltip/AlertTooltip";
import StoreModelFabric from "@/internal/lib/storeModels/fabrics/storeModelFabric";

const ApartmentPage = ({selectedObject}) => {
  const {id} = useParams()
  const resetForm = changeApartmentFormSelectors.useResetForm()
  const [isChanging, setIsChanging] = useState(false)
  const [apartmentsList, setApartmentsList] = mkdsStateSelectors.apartmentsList.useState()
  const currentMKD = mkdsStateSelectors.selectedMKD.useValue()
  const [navigateBack] = useNavigateBack()
  const [isOpenDeleteModal, toggleDeleteModal] = useToggle(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [lastGetEnumeratorsRequest, setLastGetEnumeratorsRequest] = useState(null)
  const [isCreatingEnumerator, setIsCreatingEnumerator] = useState(false)
  const [enumerators, setEnumerators] = mkdsStateSelectors.enumeratorsList.useState()
  const resetNewEnumeratorForm = newEnumeratorFormSelectors.useResetForm()
  const [isChangingEnumerator, setIsChangingEnumerator] = useState(false)
  const resetChangeEnumeratorForm = changeEnumeratorFormSelectors.useResetForm()
  const [editEnumeratorId, setEditEnumeratorId] = useState(null)
  const [isOpenDeleteEnumeratorModal, toggleDeleteEnumeratorModal] = useToggle(false)
  const [isDeletingEnumerator, setIsDeletingEnumerator] = useState(false)
  const setMessage = selectors.useSetMessage()
  const [findList, setFindList, resetFindList] = mkdsStateSelectors.findApartmentsList.useState()
  const [serialValidations, setSerialValidations] = useState('')
  const [enumeratorsCount, setEnumeratorsCount] = useState(0)
  const updateAlertTrigger = mkdsStateSelectors.updateAlertTrigger.useValue()
  const toggleUpdateAlertTrigger = mkdsStateSelectors.updateAlertTrigger.useToggleValue();

  useEffect(() => {
    return () => {
      resetForm()
      resetNewEnumeratorForm()
      resetChangeEnumeratorForm()
      if (lastGetEnumeratorsRequest) {
        lastGetEnumeratorsRequest.abort()
      }
    }
  }, [])

  useEffect(() => {
    if (!id) return

    if (lastGetEnumeratorsRequest) {
      lastGetEnumeratorsRequest.abort()
    }

    const {promise, controller} = apartmentsEndpoint.getEnumerators(id)
    setLastGetEnumeratorsRequest(controller)
    promise
      .then(res => {
        if (Array.isArray(res)) {
          setEnumerators(res)
          setEnumeratorsCount(res.length)
        } else {
          console.log(res)
        }
        setLastGetEnumeratorsRequest(null)
      })
      .catch(err => {
        if (err.name === 'AbortError') return
        setLastGetEnumeratorsRequest(null)
      })
  }, [id, updateAlertTrigger])

  const handleChangeApartment = (data) => {
    setIsChanging(true)

    const {promise} = apartmentsEndpoint.change(data, id, currentMKD)
    promise
      .then(res => {
        if ('email' in res) {
          const newList = [...apartmentsList]
          const changingIndex = newList.findIndex((apartment) => apartment.id == id)

          const newFindList = [...(findList ?? [])]
          const changingFindIndex = (findList ?? []).findIndex((apartment) => apartment.id == id)

          if (changingIndex >= 0) {
            newList[changingIndex] = res
            setApartmentsList(newList)
          }

          if (changingFindIndex >= 0) {
            newFindList[changingFindIndex] = res
            setFindList(newFindList)
          }
          navigateBack()
          resetForm()
          setMessage({
            header: 'Изменение квартиры',
            message: 'Данные квартиры успешно изменены',
            type: 'success',
            count: 3000
          })
        } else if (res?.detail === 'double apartment') {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Квартира с данным номером уже существует',
            type: 'error',
            count: 3000
          })
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось изменить данные квартиры',
            type: 'error',
            count: 3000
          })
          console.log(res)
        }
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось изменить данные квартиры',
          type: 'error',
          count: 3000
        })
        console.log(err)
      })
      .finally(() => setIsChanging(false))
  }

  const handleDeleteApartment = (id) => {
    setIsDeleting(true)

    const {promise} = apartmentsEndpoint.delete(id)
    promise
      .then(res => {
        if (res?.status === 'ok') {
          const newList = apartmentsList.filter(apartment => apartment.id != id)
          const newFindList = findList?.filter(apartment => apartment.id != id)
          setApartmentsList(newList)
          setFindList(newFindList)
          navigateBack(2)
          resetForm()
          setMessage({
            header: 'Удаление квартиры',
            message: 'Квартира успешно удалена',
            type: 'success',
            count: 3000
          })
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось удалить квартиру',
            type: 'error',
            count: 3000
          })
          console.log(res)
        }
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось удалить квартиру',
          type: 'error',
          count: 3000
        })
        console.log(err)
      })
      .finally(() => setIsDeleting(false))
  }

  const handleChangeSerial = () => {
    setSerialValidations('')
  }

  const handleCreateEnumerator = (data) => {
    setIsCreatingEnumerator(true)

    const {promise} = enumeratorEndpoint.create({...data, apartment: +id})
    promise
      .then(res => {
        if ('id' in res) {
          setEnumerators([res, ...enumerators])
          setEnumeratorsCount(prev => prev + 1)
          navigateBack()
          resetNewEnumeratorForm()
          setMessage({
            header: 'Добавлен ОП',
            message: 'ОП успешно добавлен',
            type: 'success',
            count: 3000
          })
        } else if ('serial' in res) {
          setSerialValidations(res.serial[0])
        } else if (res?.detail === 'double number') {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'ОП с данным номером уже существует',
            type: 'error',
            count: 3000
          })
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось добавить ОП',
            type: 'error',
            count: 3000
          })
          console.log(res)
        }
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось добавить ОП',
          type: 'error',
          count: 3000
        })
        console.log(err)
      })
      .finally(() => setIsCreatingEnumerator(false))
  }

  const handleChangeEnumerator = (data) => {
    setIsChangingEnumerator(true)

    const {promise} = enumeratorEndpoint.change(data, editEnumeratorId)

    promise
      .then(res => {
        if ('id' in res) {
          const newList = [...enumerators]
          const changingIndex = newList.findIndex((enumerator) => enumerator.id == res.id)

          if (changingIndex >= 0) {
            newList[changingIndex] = res
            setEnumerators(newList)
            navigateBack()
            resetChangeEnumeratorForm()
          }
          setMessage({
            header: 'Изменение ОП',
            message: 'Данные ОП успешно изменены',
            type: 'success',
            count: 3000
          })
          toggleUpdateAlertTrigger();
        } else if ('serial' in res) {
          setSerialValidations(res.serial[0])
        } else if (res?.detail === 'double number') {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'ОП с данным номером уже существует',
            type: 'error',
            count: 3000
          })
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось изменить данные ОП',
            type: 'error',
            count: 3000
          })
          console.log(res)
        }
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось изменить данные ОП',
          type: 'error',
          count: 3000
        })
        console.log(err)
      })
      .finally(() => setIsChangingEnumerator(false))
  }

  const handleDeleteEnumerator = (id) => {
    setIsDeletingEnumerator(true)

    const {promise} = enumeratorEndpoint.delete(id)
    promise
      .then(res => {
        if (res?.status === 'ok') {
          const newList = enumerators.filter(enumerator => enumerator.id != id)
          setEnumerators(newList)
          toggleDeleteEnumeratorModal()
          navigateBack()
          resetChangeEnumeratorForm()
          setMessage({
            header: 'Удаление ОП',
            message: 'ОП успешно удален',
            type: 'success',
            count: 3000
          })
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось удалить ОП',
            type: 'error',
            count: 3000
          })
          console.log(res)
        }
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось удалить ОП',
          type: 'error',
          count: 3000
        })
        console.log(err)
      })
      .finally(() => setIsDeletingEnumerator(false))
  }

  const selectedApartment = ([...apartmentsList, ...(findList ?? [])]).find(apartment => apartment.id == id)

  return (
    <>
      <div className={'apartment-page'}>
        <ArrowBack
          pathOnVisible={PATHS.apartments}
          withId={true}
        />

        <div className="apartment-page__title-wrapper">
          <h2 className="title">
            {MKDHelper.getAddressWithApartments(selectedObject, selectedApartment?.kv)}
          </h2>
        </div>

        <div className={'apartment-page__settings'}>
          <LinkTab
            path={PATHS.owner}
            text={'Собственник квартиры'}
          />

          <LinkTab
            path={PATHS.settings}
            text={'Параметры квартиры'}
          />
        </div>

        <div className={'apartment-page__list-title'}>
          <h4>Отопительные приборы</h4>

          <HoveredPopover text={'Добавить отопительный прибор'}>
            <NavLink
              className={`btn btn_transparent-bg`}
              to={PATHS.newHeatMeter}
            >
              +
            </NavLink>
          </HoveredPopover>
        </div>

        <Loader loading={!!lastGetEnumeratorsRequest}>
          {!!enumerators.length
            ? enumerators.map(enumerator => <LinkTab
              key={enumerator.id}
              path={enumerator.id.toString()}
              text={`ОП №${enumerator.number}`}
              onClick={() => setEditEnumeratorId(enumerator.id)}
              extraComponent={enumerator?.enumerator_editable ? <div style={{
                position: "absolute",
                top: '50%',
                transform: 'translateY(-50%)',
                right: '3rem'
              }}>
                <AlertTooltip text={'Нет показаний'}/>
              </div> : null}
            />)
            : <p className={'apartments-page__empty-list'}>Список пуст. Вы можете добавить отопительный прибор нажав на +</p>
          }
        </Loader>
      </div>

      <Routes>
        <Route
          path={PATHS.newHeatMeter}
          element={<EnumeratorForm
            isNew
            enumeratorsCount={enumeratorsCount}
            pathOnVisible={PATHS.newHeatMeter}
            formSelectors={newEnumeratorFormSelectors}
            onValidSubmit={handleCreateEnumerator}
            isLoading={isCreatingEnumerator}
            serialValidations={serialValidations}
            handleChangeSerial={handleChangeSerial}
            setSerialValidations={setSerialValidations}
          />}
        />

        <Route
          path={':id/'}
          element={<EnumeratorForm
            onDelete={handleDeleteEnumerator}
            isDeleting={isDeletingEnumerator}
            isOpenDeleteModal={isOpenDeleteEnumeratorModal}
            toggleDeleteModal={toggleDeleteEnumeratorModal}
            formSelectors={changeEnumeratorFormSelectors}
            onValidSubmit={handleChangeEnumerator}
            isLoading={isChangingEnumerator}
            serialValidations={serialValidations}
            handleChangeSerial={handleChangeSerial}
            setSerialValidations={setSerialValidations}
          />}
        />

        <Route
          path={PATHS.settings}
          element={<ApartmentForm
            onDelete={handleDeleteApartment}
            isDeleting={isDeleting}
            isOpenDeleteModal={isOpenDeleteModal}
            toggleDeleteModal={toggleDeleteModal}
            formSelectors={changeApartmentFormSelectors}
            pathOnVisible={PATHS.settings}
            editId={id}
            isLoading={isChanging}
            onValidSubmit={handleChangeApartment}
          />}
        />

        <Route
          path={PATHS.owner}
          element={<ApartmentOwnerPage selectedApartment={selectedApartment}/>}
        />
      </Routes>
    </>
  )
}

export default ApartmentPage
