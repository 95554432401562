import React, {useEffect, useRef, useState} from "react";
import Loader from "@/presentation/components/loader/Loader";
import ownerApartmentEndpoints from "@/data/api_entities/owner_apartment/owner_apartment.endpoints";
import {selectors} from "@/presentation/shared/ui/message";
import {FormattedDate, FormattedMessage} from "react-intl";
import {RESOURCES_TYPES} from "@/internal/content/consts";
import {API_URL} from "@/config";

const HistoryList = ({apartmentId}) => {
  const [loading, setLoading] = useState(true)
  const listRequestController = useRef();
  const [list, setList] = useState([])
  const [loadingReportUrl,setLoadingReportUrl] = useState(false)
  const setMessage = selectors.useSetMessage()

  useEffect(() => {
    return () => listRequestController.current?.abort()
  }, [])

  useEffect(() => {
    if (apartmentId) {
      setLoading(true)
      listRequestController.current?.abort()

      const {promise, contoller} = ownerApartmentEndpoints.getReportsHistory(apartmentId)
      listRequestController.current = contoller
      promise
        .then((res) => {
          if (Array.isArray(res)) {
            setList(res)
          } else {
            setMessage({
              header: <FormattedMessage id={'requests.error'}/>,
              message: 'Не удалось загрузить история расчетов',
              type: 'error',
              count: 3000
            })
            setList([])
          }
          setLoading(false)
        })
        .catch((err) => {
          if (err.name === 'AbortError') return
          setLoading(false)
          setList([])
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось загрузить история расчетов',
            type: 'error',
            count: 3000
          })
        })
    }
  }, [apartmentId])

  const handleClick = (historyId) => {
    if (loadingReportUrl || !apartmentId) return;
    setLoadingReportUrl(true)

    const {promise} = ownerApartmentEndpoints.getReportUrl(historyId, apartmentId)

    promise.then((res) => {
      if (res.url && !res.url.includes('http')) {
        const link = document.createElement("a");
        link.href = API_URL + res.url;

        link.style.display = 'none';
        link.setAttribute('download', '');
        link.setAttribute('target', '_blank');

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      } else {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось скачать отчет',
          type: 'error',
          count: 3000
        })
      }

    }).catch((err) => {
      setMessage({
        header: <FormattedMessage id={'requests.error'}/>,
        message: 'Не удалось скачать отчет',
        type: 'error',
        count: 3000
      })
    })
      .finally(() => setLoadingReportUrl(false))
  }

  return (
    <Loader  styles={{
      wrapper: (base) => ({
        ...base,
        width: '100%',
        maxWidth: '100%',
        overflow: 'auto',
        flex: 1,
        marginBottom: '1.25rem'
      })
    }} loading={loading || loadingReportUrl}>
      <div className={'reports-history__list'}>
        {list.length
          ? list.map((history) => {
            return (
              <div key={history.id} className={'reports-history__card'} onClick={() => handleClick(history.id)}>
                <div className="reports-history__card-img">
                  <img src={RESOURCES_TYPES[3].img} alt="hot"/>
                </div>
                <div className="reports-history__card-info">
                  <h6>{history.name}</h6>
                  <span>
                    <FormattedDate value={history.start_date} month={'long'} year={'numeric'}/> - <FormattedDate value={history.end_date} month={'long'} year={'numeric'}/>
                  </span>
                </div>
              </div>
            )
          })
          : <p>История пуста</p>
        }
      </div>
    </Loader>
  )
}

export default HistoryList;
