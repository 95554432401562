import {addedZero, capitalizeFirstLetter} from "@/internal/formatters/formatters";
import {FormattedDate} from "react-intl";

class MKDHelpers {
  getAddress(object) {
    if (!object) return '-'
    const street = (object?.street ?? '').length > 0
      ? `${object?.street} `
      : ''
    return street + (object?.building ?? '')
  }

  getRegion(object) {
    if (!object) return '-'
    const region = (object?.region ?? '').length > 0
      ? `${object?.region}, `
      : ''
    return region + (object?.city ?? '')
  }

  getAddressWithApartments(object, apartment) {
    return `${this.getAddress(object)}, кв ${apartment}`
  }

  dateFormat(dateString) {
    if (!dateString) return '-';
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return <span>
      <span style={{whiteSpace: 'nowrap'}}>{year}-{month}-{day}</span> <span style={{whiteSpace: 'nowrap'}}>{hours}:{minutes}:{seconds}</span>
    </span>;
  }

  formatDateRange(start, end) {
    if (!start || !end) return '-'
    if (start.slice(5, 7) === end.slice(5,7)) {
      return <FormattedDate
        children={capitalizeFirstLetter}
        value={start}
        month={'long'}
        year={'numeric'}
      />
    } else {
      return (
        <>
          <FormattedDate
            children={capitalizeFirstLetter}
            value={start}
            month={'long'}
            year={'numeric'}
          />
          {' - '}
          <FormattedDate
            children={capitalizeFirstLetter}
            value={end}
            month={'long'}
            year={'numeric'}
          />
        </>
      )
    }

  }

  getAlertText(enumeratorAlert, mansionAlert) {
    const alerts = [enumeratorAlert, mansionAlert].filter(Boolean)

    return alerts.join(' / ')
  }

  formatNumber = (number) => {
    if (number === null) {
      return '-';
    }
    if (typeof number === 'number') {
      return number.toFixed(4);
    }
    throw new Error('Недопустимый тип данных: ожидалось число или null');
  }

  isEndOfMonth(dateString) {
    if (!dateString?.trim()) return false
    const date = new Date(dateString);
    const nextDay = new Date(date);
    nextDay.setDate(date.getDate() + 1); // Переход к следующему дню

    return nextDay.getMonth() !== date.getMonth();
  }

  getPeriodDate(dateString, checkTime = false) {
    if (!dateString) return;

    const date = new Date(dateString)
    const month = addedZero(date.getMonth() + 1)
    const day = addedZero(date.getDate())
    const dateFormatted = `${day}.${month}.${date.getFullYear()}`
    if (!checkTime) {
      return `${dateFormatted} 00:00:00`;
    } else {
      return this.isEndOfMonth(dateString)
        ? `${dateFormatted} 24:00:00`
        : `${dateFormatted} 00:00:00`
    }
  }
}

export const MKDHelper = new MKDHelpers()
