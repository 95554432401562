export const INIT_USER_DATA = Object.freeze({
  id: null,
  login: '',
  balance: 0,
  bonus: 0,
  avatar: null,
  email: '',
  permissions: [],
  phone: '',
  account_number: null,
  service_manager: null,
  owner_apartment: null
})

export const INIT_CHANGE_USER_DATA = Object.freeze({
  login: '',
  phone: '',
})

export const INIT_CHANGE_USER_DATA_VALIDATIONS = Object.freeze({
  phone: [],
  login: []
})

export const INIT_CHANGE_EMAIL_FORM_DATA = Object.freeze({
  new_email: '',
})

export const INIT_CHANGE_EMAIL_VALIDATIONS = Object.freeze({
  new_email: [],
})

export const INIT_CHANGE_PASSWORD_DATA = Object.freeze({
  new_password: '',
  repeatedPassword: ''
})

export const INIT_CHANGE_PASSWORD_VALIDATIONS = Object.freeze({
  new_password: [],
  repeatedPassword: []
})

export const INIT_CHANGE_ENTITY_DATA = Object.freeze({
  inn: '',
  name: '',
  address: '',
  actual_address: '',
  contact: ''
})

export const INIT_CHANGE_ENTITY_VALIDATIONS = Object.freeze({
  inn: [],
  name: [],
  address: [],
  actual_address: [],
  contact: []
})

export const INIT_CHANGE_ADDRESS_DATA = Object.freeze({
  id: 0,
  name: '',
  region: '',
  city: '',
  street: '',
  building: '',
  object_type: 0,
  apartment: '',
  residents: '',
  area: '',
  geo_len: '',
  geo_lat: '',
  tariff: '',
  currency_type: 1,
  unit_type: 1,
  servicemanager: 0
})
