import ArrowBack from "@/presentation/components/common/ArrowBack";
import Input from "@/presentation/components/common/formComponents/Input";
import React, {useEffect, useMemo, useRef, useState} from "react";
import Loader from "@/presentation/components/loader/Loader";
import {FormattedMessage} from "react-intl";
import {userDataSelectors} from "@/internal/lib/storeModels/models/user/userDataModel";
import {useParams} from "react-router-dom";
import {enumeratorEndpoint} from "@/data/api_entities/enumerator/enumartaor_endpoint";
import DefaultModal from "@/presentation/components/common/modals/DefaultModal";
import {selectors} from "@/presentation/shared/ui/message";
import SearchSelect from "@/presentation/components/common/selects/SearchSelect";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import {mkdsStateSelectors} from "@/internal/lib/storeModels/models/mkds/mkdsState";
import {emulateTab} from "emulate-tab";
import {MKDHelper} from "@/presentation/components/heatCalculationPage/helpers";

const EnumeratorForm = (
  {
    isOpenDeleteModal = false,
    toggleDeleteModal,
    onDelete,
    isDeleting = false,
    onValidSubmit,
    pathOnVisible,
    formSelectors,
    isNew = false,
    isLoading = false,
    serialValidations = '',
    handleChangeSerial,
    setSerialValidations,
    enumeratorsCount = null
  }) => {
  const {id} = useParams()
  const userData = userDataSelectors.userData.useValue()
  const isValidForm = formSelectors.useIsFormValid()
  const onSubmitForm = formSelectors.useOnClickSubmit()
  const setInitialData = formSelectors.useSetInitFormData()
  const setOnValidSubmit = formSelectors.useSetOnValidSubmit()
  const number =  formSelectors.useFormDataValue('number')
  const [lastGetDataRequest, setLastGetDataRequest] = useState(null)
  const [firstLoading, setFirstLoading] = useState(true)
  const setMessage = selectors.useSetMessage()
  const setCoeff = formSelectors.useSetFormDataValue('coefficient')
  const [isOpenModal, toggleModal] = useToggle()
  const [requestText, setRequestText] = useState('')
  const [requestLoading, setRequestLoading] = useState(false)
  const selectedMkd = mkdsStateSelectors.selectedMKD.useValue()
  const setFormValue = (key) => formSelectors.setFormDataValue(key)
  const autoReport = formSelectors.useFormDataValue('auto_report')
  const [data, setData] = useState(null)

  const searchEnumeratorsRequest = useRef();
  const [isLoadingEnumerators, setIsLoadingEnumerators] = useState(false);
  const searchEnumerators = async (value) => {
    try {
      setIsLoadingEnumerators(true)
      searchEnumeratorsRequest.current?.abort();

      const {promise, controller} = enumeratorEndpoint.find(value, selectedMkd);
      searchEnumeratorsRequest.current = controller;
      const res = await promise;
      let options = [];
      if (Array.isArray(res?.results)) {
        options = res.results.map(item => {
          return {
            value: item.coefficient,
            item,
            label: (
              <span style={{
                display: 'flex',
                flexDirection: 'column'
              }}>
                <span>{item.name}</span>
                <span style={{
                  fontSize: '12px',
                  color: '#727A86'
                }}>{item.сoolant_supply}</span>
              </span>
            )
          }
        })
      } else {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось загрузить список ОП',
          type: 'error',
          count: 3000
        })
      }

      searchEnumeratorsRequest.current = null;
      setIsLoadingEnumerators(false)
      return options;
    } catch (err) {
      if (err.name === 'AbortError') return null;
      console.log('err')
      searchEnumeratorsRequest.current = null;
      setIsLoadingEnumerators(false)
      return [];
    }
  }

  const loading = useMemo(() => {
    return isNew
      ? isLoading
      : firstLoading || isLoading || !!lastGetDataRequest
  }, [isNew, firstLoading, lastGetDataRequest, isLoading])

  useEffect(() => {
    setOnValidSubmit(onValidSubmit)
  }, [onValidSubmit])
  console.log(data)
  useEffect(() => {
    setSerialValidations('')
    if (isNew || !id) {
      setInitialData({
        number: enumeratorsCount + 1
      })
    } else {
      if (lastGetDataRequest) {
        lastGetDataRequest.abort()
      }

      const {promise, controller} = enumeratorEndpoint.getById(id)
      setLastGetDataRequest(controller)
      promise
        .then(res => {
          if ('id' in res) {
            setInitialData(res)
            setData(res)
            setLastGetDataRequest(null)
          } else {
            setData(null)
            setMessage({
              header: <FormattedMessage id={'requests.error'}/>,
              message: 'Не удалось получить данные ОП',
              type: 'error',
              count: 3000
            })
            console.log(res)
          }
        })
        .catch(err => {
          if (err.name === 'AbortError') return
          setData(null)
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось получить данные ОП',
            type: 'error',
            count: 3000
          })
          setLastGetDataRequest(null)
        })
        .finally(() => {
          if (firstLoading) setFirstLoading(false)
        })
    }
  }, [isNew, id])

  const handleSubmit = () => {
    if (!isValidForm) return

    onSubmitForm()
  }

  const onSelectEnumerator = (option) => {
    setCoeff(option.value)
  }

  const sendTypeRequest = () => {
    setRequestLoading(true)
    const {promise} = enumeratorEndpoint.sendRequest(requestText)

    promise
      .then(res => {
        if (res?.status === 'ok') {
          toggleModal()
          setRequestText('')
          setMessage({
            header: 'Запрос на добавление типа ОП',
            message: 'Запрос на добавление типа ОП успешно отправлен',
            type: 'success',
            count: 3000
          })
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось отправить запрос на добавление',
            type: 'error',
            count: 3000
          })
          console.log(res)
        }
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось отправить запрос на добавление',
          type: 'error',
          count: 3000
        })
      })
      .finally(() => setRequestLoading(false))
  }

  const onPressEnter = (e) => {
    if (e.code !== 'Enter') return

    e.stopPropagation()
    emulateTab()
  }

  return (
    <div className={'enumerator-form'} onKeyDown={onPressEnter}>
      <ArrowBack
        pathOnVisible={pathOnVisible ?? id}
      />

      <div className="enumerator-form__title-wrapper">
        <h2 className="title">
          {isNew
            ? 'Добавление отопительного прибора'
            : `Параметры распределителя №${number}`
          }
        </h2>
      </div>

      <Loader loading={loading}>
        <div className="form-fields">
          <Input
            formSelectors={formSelectors}
            name={'number'}
            label={'Номер ОП по квартире'}
            valueFormatter={(value) => !!value.length ? +value : value}
            type={'number'}
          />

          <Input
            formSelectors={formSelectors}
            name={'serial'}
            label={'Серийный номер распределителя'}
            withChangeValue={handleChangeSerial}
            additionalValidationMsg={serialValidations}
          />

          <div className="form-group enumerator-form__search">
            <label>Тип ОП</label>
            <SearchSelect
              onSelect={onSelectEnumerator}
              searchOptionsByValue={searchEnumerators}
              id={selectedMkd}
              isLoading={isLoadingEnumerators}
            />
            <span
              className={'enumerator-form__link'}
              onClick={() => toggleModal()}
            >
               Не нашли нужный тип?
             </span>
          </div>

          <Input
            formSelectors={formSelectors}
            name={'coefficient'}
            label={'Коэффициент типа ОП'}
            valueFormatter={(value) => !!value.length ? +value : value}
            type={'number'}
          />

          <Input
            formSelectors={formSelectors}
            name={'power'}
            label={'Мощность ОП, КВт/ч'}
            valueFormatter={(value) => !!value.length ? +value : value}
            type={'number'}
          />

          {!isNew && !!data?.date && <Input
            formSelectors={formSelectors}
            name={'indication'}
            label={<span>Показания распределителя №{number} на {MKDHelper.dateFormat(data.date)}</span>}
            valueFormatter={(value) => !!value.length ? +value : value}
            type={'number'}
          />}


          <div style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '8px'
          }}>
            <input
              className="checkbox"
              type="checkbox"
              id="checkbox-id"
              name="auto_report"
              style={{
                cursor: 'pointer',
              }}
              checked={autoReport ?? false}
              value={autoReport ?? false}
              onClick={(event) => {
                setFormValue('auto_report')(event.target.checked)
              }}
            />

            <span style={{
              fontSize: '14px',
            }}>
              Автосбор показаний
            </span>
          </div>
        </div>

        <div className="enumerator-form__btns">
          <button
            className="btn"
            onClick={handleSubmit}
            disabled={!isValidForm}
          >
            <FormattedMessage id={'app.save'}/>
          </button>

          {userData.service_manager || isNew
            ? null
            : (
              <button
                className='btn btn_red'
                onClick={toggleDeleteModal}
              >
                <FormattedMessage id={'app.delete'}/>
              </button>
            )
          }
        </div>
      </Loader>

      <DefaultModal
        loading={isDeleting}
        isOpen={isOpenDeleteModal}
        toggleModal={toggleDeleteModal}
        agreeBtnConfig={{
          className: 'btn btn_red',
          text: <FormattedMessage id={'app.delete'}/>
        }}
        onAgree={() => onDelete(id)}
        onDisagree={toggleDeleteModal}
        headerTitle={'Удаление отопительного прибора'}
      />

      <DefaultModal
        isOpen={isOpenModal}
        toggleModal={toggleModal}
        agreeBtnConfig={{
          className: 'btn',
          text: 'Отправить запрос'
        }}
        loading={requestLoading}
        onAgree={sendTypeRequest}
        onDisagree={toggleModal}
        headerTitle={'Запрос на добавление типа ОП'}
        bodyText={<div className={'enumerator-form__request'}>
          <p>Оставьте заявку на добавление типа ОП и мы свяжемся с вами в ближайшее время!</p>

          <textarea
            rows={2}
            value={requestText}
            onChange={(e) => setRequestText(e.target.value)}
          />
        </div>}
      />
    </div>
  )
}

export default EnumeratorForm
