import Input from "@/presentation/components/common/formComponents/Input";
import {FormattedMessage} from "react-intl";
import {
  newOwnerFormControllerSelectors
} from "@/presentation/components/heatCalculationPage/components/MKDPage/components/ApartmentOwnerPage/form/controller";

const RegisterOwnerForm = () => {
  return (
    <>
      <Input
        name={'email'}
        type="email"
        label={<FormattedMessage id={'authorization.e-mail'}/>}
        formSelectors={newOwnerFormControllerSelectors}
      />
    </>
  )
}

export default RegisterOwnerForm;