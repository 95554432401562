import {
  deviceSerialNumberValidator,
  emailValidator,
  geoLatValidator, geoLonValidator, INNValidator, isEmptyObject, maxLengthValidator,
  notMinusValue,
  notZeroFirst,
  notZeroValidator, minMaxValueValidator,
  phoneValidator, repeatPasswordValidator,
  requiredValidator
} from "./validationsRules";
import {
  EMAIL_VALIDATION_MSG,
  getIncorrectSerialNumberMsg,
  innMsg,
  longLoginValidationMsg,
  longNameValidationMsg,
  notFullRangeData,
  notMinusMsg,
  notZeroMsg,
  phoneValidationMsg,
  repeatPasswordMsg,
  requiredMsg,
  wrongLatValueMsg,
  wrongLonValueMsg,
  wrongValue
} from "./validationsMessages";
import {DEVICE_REPORT_TYPE} from "@/presentation/components/reportsPage/const";
import DevicesTypes from "@/presentation/components/devicesPage/devices_types";

const validateState = (validatorRule, validatorArgs, state, msg) => {
  let stateClone = [...state]
  const values = Array.isArray(validatorArgs) ? validatorArgs : [validatorArgs]

  if (!validatorRule(...values) && !state.includes(msg)) {
    stateClone = [msg]
  } else if (validatorRule(...values)) {
    const filteredState = stateClone.filter(item => item !== msg)

    stateClone = [...filteredState]
  }

  return stateClone
}

export const getDeviceFormValidators = (formData) => {
  return  {
    serial: (value) => {
      if (formData.type === 0) return []
      if (!requiredValidator(value)) return [requiredMsg]

      switch (formData.type) {
        case DevicesTypes.GSMBridge:
          return deviceSerialNumberValidator(value, 'FB')
            ? []
            : [getIncorrectSerialNumberMsg('FB')]
        case DevicesTypes.electronicFlowMeter:
          return deviceSerialNumberValidator(value, '64')
            ? []
            : [getIncorrectSerialNumberMsg('64')]
        case DevicesTypes.xPulseOld:
          return deviceSerialNumberValidator(value, '03')
            ? []
            : [getIncorrectSerialNumberMsg('03')]
        case DevicesTypes.bridge:
          return deviceSerialNumberValidator(value, 'FA')
            ? []
            : [getIncorrectSerialNumberMsg('FA')]
        default:
          break;
      }

      return []
    },
    sim: (value) => {
      if (formData.type === 0 ||
        formData.type === DevicesTypes.xPulseOld ||
        formData.type === DevicesTypes.electronicFlowMeter ||
        formData.type === DevicesTypes.heatMeter) return []

      return !!value
        ? phoneValidator(value)
          ? []
          : [phoneValidationMsg]
        : [requiredMsg]
    },
    rsAddress: (value) => {
      if (formData.type !== DevicesTypes.heatMeter) return []

      return requiredValidator(value)
        ? value <= 255 && value >= 0
          ? []
          : [wrongValue]
        : [requiredMsg]
    }
  }
}

const greatNumberValidator = (value, prevState) => {
  let state = [...prevState]

  state = validateState(notMinusValue, value, state, notMinusMsg)
  state = validateState(notZeroFirst, value, state, wrongValue)
  state = validateState(notZeroValidator, value, state, notZeroMsg)

  return value
    ? state
    : []
}

export const getAddressFormValidators = (formData, prevState) => {
  return {
    name: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    tariff: (value) => greatNumberValidator(value, prevState),
    area: (value) => greatNumberValidator(value, prevState),
    geo_lat: (value) => {
      let state = [...prevState]

      state = validateState(geoLatValidator, value, state, wrongLatValueMsg)
      state = validateState(notZeroFirst, value, state, wrongValue)

      return value
        ? state
        : []
    },
    geo_len: (value) => {
      let state = [...prevState]

      state = validateState(geoLonValidator, value, state, wrongLonValueMsg)
      state = validateState(notZeroFirst, value, state, wrongValue)

      return value
        ? state
        : []
    }
  }
}

export const getAnalyticAddressValidator = (formData, prevState) => {
  return {
    name: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    tariff: (value) => {
      let state = [...prevState]

      state = validateState(notMinusValue, value, state, notMinusMsg)
      state = validateState(notZeroValidator, value, state, wrongValue)

      return state
    },
    area: (value) => {
      let state = [...prevState]

      state = validateState(notMinusValue, value, state, notMinusMsg)
      state = validateState(notZeroValidator, value, state, wrongValue)

      return state
    },
    geo_lat: (value) => {
      let state = [...prevState]

      state = validateState(geoLatValidator, value, state, wrongLatValueMsg)
      state = validateState(notZeroFirst, value, state, wrongValue)
      state = validateState(requiredValidator, value, state, requiredMsg)

      return state
    },
    geo_len: (value) => {
      let state = [...prevState]

      state = validateState(geoLonValidator, value, state, wrongLonValueMsg)
      state = validateState(notZeroFirst, value, state, wrongValue)
      state = validateState(requiredValidator, value, state, requiredMsg)

      return state
    }
  }
}

export const getSignInValidator = (formData, prevState) => {
  return {
    email: (value) => {
      let state = [...prevState]

      state = validateState(emailValidator, [value], state, EMAIL_VALIDATION_MSG)
      state = validateState(requiredValidator, [value], state, requiredMsg)

      return state
    },
    password: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    }
  }
}

export const getUserIndividualValidator = (formData, prevState) => {
  return {
    login: (value) => {
      let state = [...prevState]

      state = validateState(maxLengthValidator, [value, 30], state, longLoginValidationMsg(30))
      state = validateState(requiredValidator, [value], state, requiredMsg)

      return state
    },
    email: (value) => {
      return emailValidator(value)
        ? []
        : [EMAIL_VALIDATION_MSG]
    },
    phone: (value) => {
      return value
        ? phoneValidator(value)
          ? []
          : [phoneValidationMsg]
        : []
    },
    password: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    }
  }
}

export const getUserCorporateValidator = () => {
  return {
    email: (value) => {
      return emailValidator(value)
        ? []
        : [EMAIL_VALIDATION_MSG]
    },
    phone: (value) => {
      return value
        ? phoneValidator(value)
          ? []
          : [phoneValidationMsg]
        : []
    },
    contact: (value) => {
      return value
        ? phoneValidator(value)
          ? []
          : [phoneValidationMsg]
        : []
    },
    inn: (value) => {
      return INNValidator(value)
        ? []
        : [innMsg]
    },
    password: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    organization_name: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
  }
}

export const getRecoveryPasswordValidator = (formData, prevState) => {
  return {
    email: (value) => {
      let state = [...prevState]

      state = validateState(emailValidator, value, state, EMAIL_VALIDATION_MSG)
      state = validateState(requiredValidator, value, state, requiredMsg)

      return state
    }
  }
}

export const getChangePassValidator = (formData) => {
  return {
    new_password: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    repeatedPassword: (value) => {
      return repeatPasswordValidator(formData.new_password, value)
        ? []
        : [repeatPasswordMsg]
    }
  }
}

export const getDeviceSimValidator = () => {
  return {
    sim: (value) => {
      return phoneValidator(value)
        ? []
        : [phoneValidationMsg]
    }
  }
}

export const getMeteringPointValidator = () => {
  return {
    name: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    device: (value) => {
      return value !== 0
        ? []
        : [requiredMsg]
    },
    type: (value) => {
      return value !== 0
        ? []
        : [requiredMsg]
    },
    device_name: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    si_number: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    serial: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    date: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    value: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    value_impulse: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    time: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    pulse: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    next_verification: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
  }
}

export const getChangeEmailValidators = () => {
  return {
    new_email: (value) => {
      return emailValidator(value)
        ? []
        : [EMAIL_VALIDATION_MSG]
    }
  }
}

export const getChangeUserDataValidators = (formData, prevState) => {
  return {
    login: (value) => {
      let state = [...prevState]

      state = validateState(maxLengthValidator, [value, 30], state, longLoginValidationMsg(30))
      state = validateState(requiredValidator, [value], state, requiredMsg)

      return state
    },
    phone: (value) => {
      return value
        ? phoneValidator(value)
          ? []
          : [phoneValidationMsg]
        : []
    }
  }
}

export const getChangeEntityValidator = () => {
  return {
    contact: (value) => {
      return value
        ? phoneValidator(value)
          ? []
          : [phoneValidationMsg]
        : [requiredMsg]
    },
    inn: (value) => {
      return INNValidator(value)
        ? []
        : [innMsg]
    },
    address: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    actual_address: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    name: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
  }
}

export const getReportFormValidators = (formData, prevState) => {
  return {
    name: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    providerPhone: (value) => {
      return value
        ? phoneValidator(value)
          ? []
          : [phoneValidationMsg]
        : []
    },
    consumerPhone: (value) => {
      return value
        ? phoneValidator(value)
          ? []
          : [phoneValidationMsg]
        : []
    },
    serviceOrgPhone: (value) => {
      return value
        ? phoneValidator(value)
          ? []
          : [phoneValidationMsg]
        : []
    },
    receivers: (value) => {
      let isValid = true

      value.filter(item => !!item).forEach(item => {
        if (!emailValidator(item)) isValid = false
      })

      return isValid
        ? []
        : [EMAIL_VALIDATION_MSG]
    },
    dimension: (value) => {
      if (formData.type === DEVICE_REPORT_TYPE) return []

      return value === 0
        ? [requiredMsg]
        : []
    },
  }
}

export const getPreconfigAnalyticValidators = (formData, prevState) => {
  return {
    name: (value) => {
      let state = [...prevState]

      state = validateState(maxLengthValidator, [value, 100], state, longNameValidationMsg(100))
      state = validateState(requiredValidator, [value], state, requiredMsg)

      return state
    },
    accounting_objects: (value) => {
      return isEmptyObject(value)
        ? []
        : [requiredMsg]
    },
    step: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    start_date: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    end_date: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
  }
}

export const getSendResultValidators = () => {
  return {
    mails: (value) => {
      let isValid = true

      value.filter(item => !!item).forEach(item => {
        if (!emailValidator(item)) isValid = false
      })

      return isValid
        ? []
        : [EMAIL_VALIDATION_MSG]
    }
  }
}

export const getAnalyticNameValidators = (formData, prevState) => {
  return {
    name: (value) => {
      let state = [...prevState]

      state = validateState(maxLengthValidator, [value, 100], state, longNameValidationMsg(100))
      state = validateState(requiredValidator, [value], state, requiredMsg)

      return state
    }
  }
}

export const getMKDFormValidators = () => {
  return {
    street: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    odn: (value) => {
      return requiredValidator(value)
        ? minMaxValueValidator(+value)
          ? []
          : [wrongValue]
        : [requiredMsg]
    },
    device_type: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    timezone: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    energy_period: (value) => {
      return minMaxValueValidator(+value, 0, 10000000)
        ? []
        : [wrongValue]
    },
    tariff: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    }
  }
}

export const getApartmentFormValidators = () => {
  return {
    kv: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    area: (value) => {
      return requiredValidator(value)
        ? notMinusValue(value)
          ? []
          : [notMinusMsg]
        : [requiredMsg]
    },
    invoice: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    email: (value) => {
      return !value.length || emailValidator(value)
        ? []
        : [EMAIL_VALIDATION_MSG]
    }
  }
}

export const getEnumeratorValidators = () => {
  return {
    number: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    coefficient: (value) => {
      return requiredValidator(value === 0 ? 1 : value)
        ? minMaxValueValidator(+value, 0, 1.999)
          ? []
          : [wrongValue]
        : [requiredMsg]
    },
    power: (value) => {
      return requiredValidator(value)
        ? minMaxValueValidator(+value, 0.1, 10)
          ? []
          : [wrongValue]
        : [requiredMsg]
    },
    serial: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    indication: (value) => {
      return minMaxValueValidator(+value, 0, 100000000)
        ? []
        : [wrongValue]
    }
  }
}

export const getCalculationSettingsValidators = (formData, prevState) => {
  return {
    name: (value) => {
      let state = [...prevState]

      state = validateState(maxLengthValidator, [value, 255], state, longNameValidationMsg(255))
      state = validateState(requiredValidator, [value], state, requiredMsg)

      return state
    },
    start_date: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    end_date: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    energy_period: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
    tariff: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
  }
}
