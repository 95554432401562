import SettingsIcon from '@mui/icons-material/Settings';
import icons from "@/assets/svg/icons/icons.svg";
import React from "react";
import {MKDHelper} from "@/presentation/components/heatCalculationPage/helpers";
import HoveredPopover from "@/presentation/components/common/HoveredPopover";
import {mkdsStateSelectors} from "@/internal/lib/storeModels/models/mkds/mkdsState";
import {useNavigate} from "react-router-dom";
import AlertTooltip from "@/presentation/components/pages/OwnerMainPage/components/AlertTooltip/AlertTooltip";

const MKDCard = ({object, onClickSettings}) => {
  const navigate = useNavigate()
  const settingsId = mkdsStateSelectors.selectedMKDSettingsId.useValue()
  const setSelectedMKD = mkdsStateSelectors.selectedMKD.useSetValue()

  const onClickCard = () => {
    setSelectedMKD(object.id)
    navigate(`${object.id}`)
  }

  return (
    <div onClick={onClickCard} className={`mkd-card ${settingsId === object.id ? 'selected' : ''}`}>
      <div className="mkd-card__body">
        <div className="mkd-card__img">
          <svg><use href={`${icons}#mansions`}/></svg>
        </div>

        <div className="mkd-card__address">
          <span>{MKDHelper.getAddress(object)}</span>
          <span>{MKDHelper.getRegion(object)}</span>
        </div>

      </div>

      <div className="mkd-card__icons">
        <HoveredPopover text={'Параметры МКД'}>
          <SettingsIcon
            onClick={(e) => onClickSettings(e, object.id)}
          />
        </HoveredPopover>
      </div>

      {
        (object?.enumerator_editable || object?.mansion_editable) && <div
          style={{
            position: 'absolute',
            bottom: '1rem',
            right: '.75rem'
          }}
        >
          <AlertTooltip text={MKDHelper.getAlertText(object?.enumerator_editable ? 'Приборы без показаний' : null, object?.mansion_editable ? 'Не указано потребление МКД' : null)}/>
        </div>
      }
    </div>
  )
}

export default MKDCard
